import Vue from 'vue';
import Vuex from 'vuex';
// import createLogger from 'vuex/dist/logger';
import HttpHelper, { apiService, isSuccess } from '@/utils/http';
import { API } from '@/utils/URLS';
import { mergeRecursive, showModal } from '@/utils/index';
import _ from 'underscore';
import locales from '@/utils/locales';
import i18n from '@/i18n';

const req = (context) => context.keys().map((key) => ({ moduleName: key, ...context(key) }));

// pages下的store
const pageModules = req(require.context('@/pages/', true, /store.js$/))
  .filter((e) => e.default)
  .reduce((accumulator, item) => {
    let fieldName = item.moduleName.replace('/store.js', '').split('/').pop();
    const { namespace, ...fields } = item.default;
    accumulator[namespace ?? fieldName] = fields;
    return accumulator;
  }, {});

// store文件夹下的store
// const storeModules = req(require.context('@/store/', true, /\.js$/))
//   .filter((e) => e.default)
//   .reduce((accumulator, item) => {
//     const { namespace, ...fields } = item.default;
//     accumulator[namespace ?? item.moduleName.slice(2, -3)] = fields;
//     return accumulator;
//   }, {});

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';
const store = new Vuex.Store({
  state: {
    // 当前账户就诊人列表
    patients: [],
  },
  actions: {
    /*
     * 新版本更新提示
     * */
    checkUpdate() {
      const updateManager = uni.getUpdateManager();
      updateManager.onUpdateReady(async function () {
        try {
          const { code, data } = await HttpHelper.get(API.APP_VERSION, {}, true);
          if (isSuccess(code)) {
            // updateStrategy 是否提示更新
            data?.updateStrategy &&
              showModal({
                title: i18n.t('i18n_c2dn769k_1651741146218_info'),
                content: i18n.t('i18n_2dzgf8ph_1651741146218_info'),
                showCancel: false,
                success() {
                  updateManager.applyUpdate(); // 新的版本已经下载好，调用 applyUpdate 应用新版本并重启
                },
              });
          }
        } catch (e) {}
      });
    },

    /**
     * 更新远程的语言资源包到本地
     * @returns {Promise<void>}
     */
    async loadRemoteLanguageWithRequest({ dispatch }, payload) {
      try {
        const { code, data } = await apiService.get('/hospital/dict/type-info/list', {
          params: {
            dictType: 'DOCUMENT',
          },
        });

        if (code === 0) {
          const resources = {
            zh: {},
            en: {},
          };
          data.forEach((item) => {
            const key = item.dictCode;
            resources['zh'][key] = item.langDataInfo['zh_CN']['dictValue'];
            resources['en'][key] = item.langDataInfo['en_US']['dictValue'];
          });

          Object.keys(resources)
            .filter((code) => !_.isEmpty(resources?.[code]))
            .forEach((code) => {
              // 合并本地和远程的配置，并使用远程的覆盖本地配置
              i18n.setLocaleMessage(code, mergeRecursive(locales[code], resources[code]));
            });
        }
      } catch (e) {
        console.warn(e);
      }
    },
  },
  mutations: {
    updatePatients(state, payload) {
      state.patients = payload;
    },
  },
  modules: {
    ...pageModules,
    // ...storeModules,
  },
  plugins: debug
    ? [
        // createLogger({
        //   filter(mutation) {
        //     // 若 mutation 需要被记录，就让它返回 true 即可
        //     // 顺便，`mutation` 是个 { type, payload } 对象
        //     return !['MessageStore', 'common'].filter((v) => mutation.type.includes(v)).length;
        //   },
        // }),
      ]
    : [],
});
Vue.use({
  install(Vue) {
    Vue.prototype.$store = store;
  },
});

export default store;

export { store as Store };
