/* eslint-disable no-undef */
import UFHStorage, { STORAGE_KEY } from '../utils/UFHStorage';
import { hideMessage } from '@/utils/Message';
import { createNamespacedHelpers } from 'vuex';
import { combineCdnUrl } from '@/utils';
import _ from 'underscore';

const { mapState } = createNamespacedHelpers('common');

const mixin = {
  data() {
    return {
      customTitle: '', // 自定义title
      query: {}, // 页面参数
      loginPhone: UFHStorage.getStorageData(STORAGE_KEY.LOGIN_PHONE) || '',
    }; 
  },
  onShow() {
    this.loginPhone = UFHStorage.getStorageData(STORAGE_KEY.LOGIN_PHONE);
    // hideMessage(); // 每次进入新页面隐藏自定义弹窗
  },
  onUnload() {
    // 重置vuex的对用state数据
    this.vuex_resetState && this.vuex_resetState();
  },
  methods: {
    /**
     * 新取cdn的图片地址 域名有变更
     * @param path
     * @return {string}
     * @private
     */
    combineCdnUrl(path) {
      return combineCdnUrl(path);
    },
    setPageTitle(title = '') {
      if (!title) {
        title = this._title;
      }
      if (this.mpType === 'page') {
        uni.setNavigationBarTitle({
          title,
        });
      }
    },
  },
  computed: {
    ...mapState({
      enUs: (state) => {
        return state.lang === 'en';
      },
    }),
    locale() {
      return this._i18n.locale;
    },
    _title() {
      if (this.mpType === 'page') {
        if (_.isFunction(this?.$options?._pageTitleFn)) {
          return this?.$options?._pageTitleFn(this);
        } else {
          return '';
        }
      }

      return '';
    },
    // navigationBarHeight() {
    //   return `${getApp().globalData.systemInfo.statusBarHeight + 44}`;
    // },
  },
  watch: {
    customTitle(value) {
      // 自定义title监听
      this.setPageTitle(value);
    },
  },
  onLoad(query = {}) {
    this.query = query;
  },
  created() {
    if (this.mpType === 'page') {
      this.setPageTitle();
    }
  },
};

export default mixin;
