// 接口请求的配置文件
// 传递接口url,或者其他配置项 如是否传递token等

export const API = {
  /**
   * 发送短信
   */
  SEND_SMS: '/app/user/send-login-code',
  /**
   * 获取 openId
   */
  AUTHORIZE: '/app/index/find-openid',
  /**
   * 登录
   */
  LOGIN: '/app/user/login',
  /**
   * 登出
   */
  LOGOUT: '/app/user/login/out',
  /**
   * 授权登录
   */
  LOGIN_AUTH: '/app/user/login-auth',
  /**
   * 首页 banner
   */
  BANNER: '/app/banner/appList',
  /**
   * 院区列表
   */
  CLINICS: '/app/hospital/list',
  /**
   * 首页带有全部信息的院区列表
   */
  INDEX_CLINICS: '/app/clinic/all/validList',
  /**
   * 搜索医生
   */
  DOCTORS: '/app/doctor/new-search',
  /**
   * 医生排班详情（排班信息医生信息）
   */
  DOCTOR_SCHEDULE: '/app/doctor/appointment-doctor',
  /**
   * 医生详情
   */
  DOCTOR: '/app/doctor/new-detail',
  /**
   * 是否收藏此医生
   */
  HAS_COLLECT: '/app/collect/isCollect',
  /**
   * 首页医生
   */
  HOME_DOCTOR: '/app/doctor/home',
  /**
   * 医院列表
   */
  HOSPITALS: '/app/clinic/all/list',
  /**
   * 科室列表
   */
  DEPARTMENTSBYHOSPITALID: '/app/doctor/find/dectorAnddepartment',
  /**
   * 科室列表
   */
  DEPARTMENTS: '/app/dapartment/list',
  /**
   * 一级科室列表 2.3.0
   */
  DEPARTMENTGROUP: '/app/department/group',
  /**
   * 根据一级获取二级科室列表 2.3.0
   * departmentGroupId
   */
  DEPARTMENTSECOND: '/app/department/second',
  /**
   * 科室预约限制及科室名称
   */
  DEPARTMENT_LIMIT: '/app/dapartment/department-limit',
  /**
   * 科室医生-按医生预约
   */
  DEPARTMENT_DOCTOR: '/app/doctor/department/doctors',
  /**
   * 科室医生-按日期预约
   */
  DEPARTMENT_DOCTOR_DATE: '/app/doctor/department/date',
  /**
   * 我的预约 预约列表
   */
  RESERVATIONS: '/v2/app/patient/merge/appt/auth/history/page',
  /**
   * 我的预约取消预约
   */
  CANCEL_RESERVATIONS: '/app/subscribe/cancel',
  /**
   * 就诊人企业合作
   */
  PATIENT_COMPANY_LIS: '/app/patient/company/list',
  /**
   * 就诊人创建
   */
  PATIENT_CREATE: '/app/patient/create',
  /**
   * 就诊人创建 since：V1.3
   * -------------------------------------------------------------- since：V1.3
   */
  PATIENT_CREATE_WITH: '/v2/app/patient/save',
  /**
   * 就诊人信息完善 since：V1.3
   * -------------------------------------------------------------- since：V1.3
   */
  PATIENT_PERFECT: '/v2/app/patient/perfect',
  /**
   * 查询所有就诊人
   */
  PATIENT_LIST: '/v2/app/patient/merge/auth/relation/list',
  /**
   * 就诊人详情
   */
  PATIENT_DETAIL: '/v2/app/patient/auth/relation/info',
  /**
   * 已绑定列表信息
   */
  PATIENT_RELATED_LIST: '/v2/app/patient/auth/relation/bind/list',
  /**
   * 就诊人（有病历）--发送验证码
   */
  PATIENT_SMS: '/app/patient/sendmsg',
  /**
   * 就诊人--OCR识别证件
   */
  PATIENT_OCR: '/app/index/id-card',
  /**
   * 就诊人--OCR识别身份证背面
   */
  PATIENT_ID_CARD_BACK_OCR: '/app/index/id-card/back',
  /**
   * 就诊人--根据证件获取就诊人信息
   */
  PATIENT_VALIDATE: '/app/patient/info',
  /**
   * 就诊人删除 since：V1.3
   * -------------------------------------------------------------- since：V1.3
   */
  PATIENT_DELETE: '/v2/app/patient/remove',
  /**
   * 就诊人授权信息
   */
  PATIENT_AUTH_INFO: '/v2/app/patient/auth/record/init',
  /**
   * 就诊人可授权信息静态列表
   */
  PATIENT_AUTH_LIST: '/v2/app/patient/auth/list',
  /**
   * 申请就诊人授权
   */
  PATIENT_AUTH: '/v2/app/patient/auth/record/apply',
  /**
   * 获取绑定的就诊人列表
   */
  PATIENT_LINKED_LIST: '/v2/app/patient/auth/relation/bind/list',
  /**
   * 解绑就诊人
   */
  PATIENT_UNLINK: '/v2/app/patient/auth/relation/unbind',
  /**
   * 就诊人--根据证件获取就诊人信息
   */
  PATIENT_BY_CREDENTIALS: '/v2/app/patient/info/credentials',
  /**
   * 添加就诊人，获取验证码 since：V1.3
   * -------------------------------------------------------------- since：V1.3
   */
  PATIENT_VERIFY_CODE: '/app/patient/new/getVerificationCode',
  /**
   * 添加就诊人，验证验证码，并返回是否已就诊 since：V1.3
   * -------------------------------------------------------------- since：V1.3
   */
  PATIENT_OLD_VERIFICATION_CODE: '/app/patient/new/verifyCode',

  /**
   * 只验证验证码
   * -------------------------------------------------------------- since：V1.3
   */
  PATIENT_JUST_VERIFY_CODE: '/app/patient/new/justVerifyCode',
  /**
   * 我的收藏
   */
  MY_DOCTOR: '/app/collect/mine/doctors',
  /**
   * 医生排班
   */
  SCHEDULE: '/app/doctor/schedule',
  /**
   * 我的账单(V2.0)
   */
  BILLS: '/v2/app/patient/merge/bill/auth/page',
  /**
   * 账单详情(V2.0)
   */
  BILL_DETAIL: '/v2/app/bill/detail',
  BILL_DETAIL_V2: '/app/bill/item-list',
  /**
   * 创建支付订单
   */
  CREATE_PAY_ORDER: '/app/pay/create',
  /**
   * 阿里云文件服务器上传前获取token
   */
  OSS_TOKEN: '/app/index/file/token',
  /**
   * 获取上传的文件URL
   */
  FILE_URL: '/app/index/file/url',
  /**
   * 预约前检查
   */
  BEFORE_APPOINTMENT: '/v2/app/patient/merge/appt/check/submit',
  /**
   * 预约确认
   */
  MAKE_APPOINTMENT: '/v2/app/patient/merge/appt/submit',
  /**
   * 初始化接口
   */
  INIT: '/app/index/init',
  /**
   * 我的优惠券
   */
  MYCOUPON: '/app/cmsUserCoupon/get/userCoupon',
  /**
   * 个人信息详情查询
   */
  PREFECT_DETAIL: '/app/patient/detail',
  /**
   * 个人信息完善
   */
  PREFECT_STATUS: '/app/patient/update/status',
  /**
   * 查询优惠券
   */
  USER_COUPON: '/app/cmsUserCoupon/get/findByUserIdAndPrice',
  /**
   * 已付列表查询优惠券
   */
  PAID_COUPON: '/app/bill/find/coupon',
  /**
   * 已使用优惠券查询使用列表
   */
  USED_COUPON_DETAIL: '/app/cmsUserCoupon/find/billItemAndAdjustment',
  /**
   * 查询国家
   */
  GET_COUNTRY: '/v2/app/dict/country',
  /**
   * 查询省份
   */
  GET_PROVINCES: '/v2/app/dict/province',
  /**
   * 查询市
   */
  GET_CITYS: '/v2/app/dict/city',
  /**
   * 查询县
   */
  GET_AREAS: '/v2/app/dict/region',
  /**
   * 活动信息列表
   */
  ACTIVITIES: '/app/hot/all',
  /**
   * 收藏-取消收藏
   */
  LIKE: '/app/collect/valid',
  /**
   * 预约确认页面信息
   */
  APPT_INFO: '/app/subscribe/confirm',
  /**
   * 就诊人商保卡
   */
  PATIENT_CARDS: '/app/subscribe/insurance-card',
  /**
   * 活动图片
   */
  ACTIVITY: '/app/channel/activity/detail',
  /**
   * 活动报名
   */
  SIGN_UP_ACTIVITY: '/app/channel/activity/submit',
  /**
   * 活动列表
   */
  CHANNEL_ACTIVITIES: '/app/channel/activity/list',
  /**
   * 活动列表-活动详情
   */
  CHANNEL_ACTIVITIES_DETAIL: '/app/channel/activity/list_detail',
  /**
   * 活动列表-活动详情
   * */
  USER_PHONE: '/app/user/login-phone',
  /**
   * 有效的活动列表
   * */
  VALID_ACTIVITIES: '/app/channel/activity/channel/all',
  /**
   * 应用版本号
   */
  APP_VERSION: '/app/mini/version',
  /**
   * 预约详情
   */
  SUBSCRIBE_DETAIL: '/app/subscribe/detail',
  /**
   * 改期详情
   */
  CHANGE_TIME_DETAIL: '/app/subscribe/change/detail',
  /**
   * 改期页面-就诊日期
   */
  CHANGE_TIME_VISIT_DATE: '/app/subscribe/visit/date',
  /**
   * 改期页面-就诊时间
   */
  CHANGE_TIME_VISIT_TIME: '/app/subscribe/visit/time',
  /**
   * 改期页面-就诊时间
   */
  CHANGE_TIME: '/app/subscribe/change',
  /**
   * 小程序首次启动记录
   */
  APP_ON_LAUNCH: '/app/user/action/launch/on',
  /**
   * 体检预约-套餐列表
   */
  PHYSICAL_PACKAGE_LIST: '/app/package/front/list',
  /**
   * 体检预约-套餐详情
   */
  PHYSICAL_PACKAGE_DETAIL: '/app/package/front/detail',
  /**
   * 体检预约-订单确认
   */
  PHYSICAL_ORDER_CONFIRM: '/app/order/front/confirm',
  /**
   * 体检预约-订单检查
   */
  PHYSICAL_ORDER_CHECK: '/app/order/front/check',
  /**
   * 体检预约-订单提交
   */
  PHYSICAL_ORDER_SUBMIT: '/app/order/front/submit',
  /**
   * 体检预约-订单列表
   */
  PHYSICAL_ORDERS: '/app/order/front/page',
  /**
   * 体检预约-订单详情
   */
  PHYSICAL_ORDER_DETAIL: '/app/order/front/detail',
  /**
   * 首页菜单列表
   */
  HOME_MENUS: '/app/main/nav/list',
  /**
   * 首页公告
   */
  HOME_NOTICE: '/app/notice/main/front',
  /**
   * 体检预约-订单支付
   */
  PHYSICAL_ORDER_PAY: '/app/order/front/pay',
  PHYSICAL_ORDER_CANCEL: '/app/order/front/cancelOrder',
  /**
   * 小程序账单开始同步接口
   */
  APP_BILL_SYNC_START: '/app/bill/sync/start',
  /**
   * 小程序账单同步状态接口
   */
  APP_BILL_SYNC_STATUS: '/app/bill/sync/status',
  /**
   * 统计
   */
  STATISTIC: '/app/stats/record/point',
  /**
   * 记录推广人
   */
  RECORD_PROMOTER: '/app/promotion/user',
  /**
   * HCRM记录用户信息（头像、昵称等）
   */
  HCRM_USER_INFO: '/app/wechat/authorize',
  /**
   * 就诊提醒订阅消息
   * --------------------------------------------------------------------------- since 1.3.1
   */
  APP_MSG_SUB_VISIT_MSG: '/app/msg/sub/visit-msg',
  /**
   * 冠状病毒预约前提示
   */
  VIRUS_BEFORE_MESSAGE: '/app/notice/appt/front',
  /**
   * 自助签到需求：当天预约记录
   */
  DECLARATION_LIST: '/v2/app/patient/merge/declaration/auth/list',
  /**
   * 自助签到需求：预约和题目信息
   */
  // CHECK_IN_DATA: '/app/declaration/appt/apply',
  CHECK_IN_DATA: '/app/declaration/appt/apply-form',
  CHECK_IN_DATA_UN_APPT: '/app/declaration/appt/apply-form-260',
  CHECK_IN_DATA_NEW: '/app/declaration/v2/appt/apply-form',
  CHECK_IN_DATA_UN_APPT_NEW: '/app/v2/declaration/appt/apply-form-260',
  CHECK_IN_DATA_VERSION: '/app/declaration/version',
  DECLARATION_ISADDPT: '/app/declaration/data',
  /**
   * 自助签到需求：提交签到信息
   */
  // SUBMIT_CHECK_IN_DATA: '/app/declaration/success/submit',
  SUBMIT_CHECK_IN_DATA: '/app/declaration/success/submit-form',
  SUBMIT_CHECK_IN_DATA_NEW: '/app/declaration/v2/success/submit-form',
  /**
   * 自助签到只发送PDF
   */
  SUBMIT_PDF_CHECK_IN_DATA: '/app/declaration/fail/submit-form',
  /**
   * 生成二维码
   */
  GET_QR_CODE: '/app/qr-code/generate',
  /**
   * 生成条形码
   */
  GET_BAR_CODE: '/app/bar-code/generate',
  /**
   * HMP退款
   */
  FRONT_APPLY_REFUND: '/app/order/front/applyRefund',

  /**
   * HMP取消退款申请
   */
  FRONT_REFUND_CANCEL: '/app/order/front/cancel',

  /**
   * HMP协商历史
   */
  REFUND_HISTORY: '/app/order/front/refundHistory',
  /**
   * 获取满意度评价详情
   */
  EVALUATION_DETAIL: '/app/satisfaction/qs/open',
  /**
   * 提交满意度评价
   */
  SUBMIT_EVALUATION: '/app/satisfaction/qs/submit',
  /**
   * 就诊列表
   */
  VISIT_RECORD: '/v2/app/patient/merge/satisfaction/qs/page',
  /**
   * 根据预约号查询满意度评价详情
   */
  SATISFACTION_QS_OPEN_APPT: '/app/satisfaction/qs/open/appt',
  /**
   * 根据手机号、性别、出生日期、姓名查找就诊人
   * 2.5.0修改为4要素
   * -------------------------------------------------------------- since：V1.3
   */
  PATIENT_NEW_FIND_BY_BASE_INFO: '/v2/app/patient/list/base/four',
  /**
   * 我的报告分页
   */
  APP_REPORT_PAGE: '/app/report/his/auth/page',
  /**
   * 我的报告分页 v2.1
   */
  APP_REPORT_PAGE_V2: '/app/v2.1/patient/merge/report/his/auth/page',
  /**
   * 我的报告-判断就诊人是否为本人
   */
  APP_REPORT_CHECK: '/app/report/check',
  /**
   * 我的报告-判断就诊人是否为本人
   */
  APP_REPORT_DETAIL: '/app/report/detail',
  /**
   * 我的报告-发送验证码
   */
  APP_REPORT_SEND: '/app/report/send',
  /**
   * 我的报告-短信验证码校验
   */
  APP_REPORT_VALID: '/app/report/valid',
  /**
   * 新数据字典接口
   */
  APP_DICT_ALL: 'app-rest/app/dict/all',
  /**
   * 地址解析
   */
  APP_ADDRESS_CLEANSE: '/v2/app/address/cleanse',
  /**
   * 报告详情获取stream
   */
  APP_REPORT_DETAIL_STREAM: '/app/report/detail-stream',
  /**
   * 已就诊，直接绑定就诊人
   */
  APP_PATIENT_BIND: '/v2/app/patient/auth/relation/bind',
  /**
   * 获取体检支付详情
   */
  MEDICAL_PLAN_INFO: '/app/order/front/base-info',
  /**
   * 获取账单支付详情
   */
  BILL_PAY_INFO: '/v2/app/bill/pay-info',
  /**
   * 待评价数量
   */
  VISIT_RECORD_NUM: '/app/satisfaction/qs/count',
  /**
   * 小程序文案
   */
  LOCALES: '/app/copywriting/front/detail',
  /**
   * 小程序文案
   */
  SUBSCRIBE_OPEN_RECORD: '/app/msg/sub/log/open',
  /**
   * 人脸识别check图片是否正规
   */
  FACE_CHECK: '/v2/app/patient/face-image',
  /**
   * 检测是否本人关系已添加
   */
  PATIENT_FAMILY_RELATION_ADD_CHECK: '/v2/app/patient/auth/check/bind/self',
  /**
   * 检测自助申报小红点数量
   */
  HOME_CHECK_IN_NUMBER: '/app/declaration/appt/list-count',
  /**
   * 检测自助签到小红点数量
   */
  HOME_SIGN_NUMBER: '/v2/app/patient/merge/arrive/count',
  /**
   * 验证就诊人权限
   */
  PATIENT_AUTH_CHECK: '/v2/app/patient/auth/check',
  /**
   * 就诊人证件列表
   */
  PATIENT_CERTIFICATE: '/v2/app/patient/certificate',
  /**
   * 更新语言
   */
  UPDATE_LANG: '/app/user/update/lang',
  /**
   * 签到列表
   */
  SIGN_LIST: '/v2/app/patient/merge/arrive/list',
  /**
   * 签到检查
   */
  SIGN_CHECK: '/v2/app/patient/arrive/check',
  /**
   * 签到
   */
  SIGN: '/v2/app/patient/arrive/submit',
  /**
   * 疫苗分类
   */
  VACCINE_CATEGORY: '/app/vaccine/category',
  /**
   * 疫苗列表
   */
  // VACCINE_LIST: '/app/vaccine/list',
  VACCINE_LIST: '/app/vaccine/modify/list',
  /**
   * 校验疫苗约满状态
   */
  VACCINE_FULL_CHECK: '/app/vaccine/appt/full/check',
  /**
   * 疫苗排班列表
   */
  VACCINE_SCHEDULE: '/app/vaccine/modify/schedule',
  /**
   * 获取核酸编号
   */
  GET_NUCLEIN_CODE: '/app/nuclein/code',
  /**
   * 核酸检测排班
   */
  GET_NUCLEIN_SCHEDULE: '/app/nuclein/schedule',
  /**
   * 核酸检测预约确认
   */
  HSJC_CONFIRM: '/app/confirm',
  /**
   * 疫苗待支付订单查询
   */
  VACCINE_CHECK_WAIT_PAY_ORDERS: '/app/vaccine/order/pay/wait/list',
  /**
   * 疫苗校验卷码
   */
  VOLUME_CODE_CHECK: '/app/vaccine/check',
  /**
   * 疫苗校验核销码
   */
  VACCINE_CHECK_COUPON_CODE: '/app/vaccine/verify-info',
  /**
   * 疫苗知情同意书签名详情
   */
  VACCINE_WRITTEN_CONSENT_DETAIL: '/app/vaccine/inform-detail',
  /**
   * 疫苗知情同意书签名提交
   */
  VACCINE_WRITTEN_CONSENT_SUBMIT: '/app/vaccine/fill-inform',
  /**
   * 我的优惠券可用数量
   */
  COUPON_NUMS: '/app/coupon/nums',
  /**
   * 查询优惠券列表
   * type: 1，优惠券 2，商城券 3，大众点评券
   */
  COUPON_List: '/app/coupon/list',
  /**
   * 无效优惠券列表
   */
  COUPON_AVAILABLE_LIST: '/app/coupon/available-list',
  /**
   * 添加优惠券
   */
  COUPON_ADD: '/app/coupon/add',
  /**
   * 优惠券同步接口
   */
  COUPON_SYNC: '/app/coupon/sync',
  /**
   * 发送模板邮件-账单
   */
  MAIL_SEND_BILL: '/app/mail/send/bill',
  /**
   * 发送模板邮件-发票
   */
  MAIL_SEND_INVOICE: '/app/mail/send/invoice',
  /**
   * 自助开票列表
   */
  INVOICING_PAGE: '/v2/app/bill/invoice/bill-invoice-page',
  /**
   * 获取开票地址
   */
  INVOICING_URL: '/v2/app/bill/invoice/open',
  /**
   * 发票详情-图片
   */
  INVOICING_PDF_IMAGE: '/v2/app/bill/invoice/image',
  /**
   * 发票详情
   */
  INVOICING_PDF_URL: '/v2/app/bill/invoice/invoice-path',
  /**
   * 疫苗预约详情
   */
  VACCINE_DETAIL: '/app/vaccine/order/confirm',
  /**
   * 疫苗预约前检查
   */
  APPT_VACCINE_BEFORE_CHECK: '/app/vaccine/order/submit/check',
  /**
   * 疫苗预约提交
   */
  APPT_VACCINE: '/app/vaccine/order/submit',
  /**
   * 根据订单编号获取疫苗预约详情
   */
  VACCINE_DETAIL_BY_ORDER_ID: '/app/vaccine/order/info',
  /**
   * 创建疫苗微信支付单
   */
  CREATE_VACCINE_PAY_ORDER: '/app/vaccine/order/pay',
  /**
   * 取消疫苗预约支付
   */
  CANCEL_VACCINE_PAY: '/app/vaccine/order/pay/cancel',
  /**
   * 创建疫苗微信支付单
   */
  VACCINE_APPT_INFO: '/app/vaccine/appt/info',
  /**
   * 取消疫苗预约
   */
  CANCEL_VACCINE_APPT: '/app/vaccine/appt/cancel',
  /**
   * 待结账
   */
  BILLS_UN_PAID: '/v2/app/patient/merge/fee/list',
  /**
   * 已结账
   */
  BILLS_PAID: '/app/bill/payed-list',
  /**
   * 账单优惠券
   */
  BILL_COUPONS: '/app/coupon/bill-coupons',
  /**
   * 账单优惠券检查
   */
  BILL_COUPONS_CHECK: '/app/coupon/check-coupons',
  /**
   * 账单支付确认
   */
  BILL_PAY_CONFIRM: '/app/episode/order/confirm',
  /**
   * 账单支付提交接口
   */
  BILL_PAY_SUBMIT: '/app/episode/order/submit',
  /**
   * 获取结账详情
   */
  EPISODE_ORDER_INFO: '/app/episode/order/info',
  /**
   * 已结账账单详情
   */
  EPISODE_BILL_INFO: '/app/episode/bill/info',
  /**
   * 预约可用时间段检查
   */
  APP_VACCINE_APPT_CHECK: '/app/vaccine/appt/check',
  /**
   * 根据手机号、性别、出生日期、姓名查找就诊人
   */
  SELECT_PATIENT_FOUR_INFO: '/v2/app/patient/list/base/four',
  /**
   * 获取会员卡
   */
  VIP_LIST: '/v2/app/patient/list/base/card',

  /**
   * 核酸检测优惠券
   */
  HSJC_COUPONS: '/app/coupon/vaccine-bill-coupons',
  /**
   * 核酸检测优惠券检查
   */
  HSJC_COUPONS_CHECK: '/app/coupon/check-coupons',
  /**
   * 创建核酸检测微信支付单
   */
  HSJC_PAY_ORDER: '/app/pay',
  /**
   * 取消疫苗预约支付
   */
  HSJC_CANCEL_PAY: '/app/pay/cancel',
  /**
   * 核酸检测预约提交
   */
  HSJC_SUBMIT: '/app/submit',
  /**
   * 核酸检测缓存/读取申报信息
   */
  HSJC_CACHE_DECLRATION: '/app/declaration/appt/submit-form-cache',
  /**
   * 核酸检测缓存/读取申报信息
   */
  HSJC_SET_CACHE_DECLRATION: '/app/declaration/appt/get-submit-form-cache',
  /**
   * 查询就诊人是否有会员权益
   */
  IS_PATIENT_HAS_VIP_PERMISSION: '/app/plus/isUserPermission/query',
  /**
   * 权益数据查询
   */
  MY_RIGHTS: '/app/plus/usePermissionList/query',
  /**
   * 会员排班查询
   */
  VIP_SCHEDULE: '/app/plus/plusSchedule/query',

  /**
   * 新就诊人接口
   */
  PATIENT_RELATION_INFO: '/v2/app/patient/relation/info',
  /**
   * 通用发送验证码接口
   */
  SEND_VALIDATION: '/app/sms/sendValidation',
  /**
   * 通用验证码验证接口
   */
  VALIDATOR_VALIDATION: '/app/sms/validation-code',
  /**
   * 查询就诊人信息通过多要素
   */
  QUERY_PATIENT_BY_MULTIPLE: '/v2/app/patient/list/base/multiple',
  GET_DATA_BY_TYPE: '/v2/app/dict/his/type/list',
  // 发送语音验证码
  SEND_VALIDATION_VOICE: '/app/sms/send-validation-voice',
  /**
   * 汉字转拼音
   */
  TO_PIN_YING: '/app/index/get-pinyin',
  // 小程序住院访客
  RELATION_PATIENT: '/v2/app/patient/merge/auth/relation/patient-list',
  RELATION_SEND: '/v2/app/patient/merge/auth/sendValidation',
  RELATION_AUTHOR: '/v2/app/patient/merge/auth/relation/authorization',
  /**
   * 更新证件照片
   */
  SAVE_ID_CARD: '/v2/app/patient/save/id-card',
  /**
   * 根据就诊人编号获取多证件信息
   */
  GET_CERTIFICATES: '/v2/app/patient/get-certificates',
  /**
   * 保存监护人
   */
  SAVE_GUARDIAN: '/v2/app/patient/save/guardian',
  /**
   * 发送授权委托书
   */
  SAVE_CONSIGN_MAIL: '/v2/app/patient/auth/send/consign/mail',
  /**
   * 报告详情直查
   */
  REPORT_HIS_DETAIL: '/app/report/his/detail',
  /**
   * 发送邮箱
   */
  MAIL_SEND_REPORT: '/app/mail/send/report',
  /**
   * 水印
   */
  GET_WATERMARK: '/app/index/get-watermark',
  /**
   * 多要素查询就诊人信息
   */
  PATIENT_INFO_BY_MULTIPLE: '/v2/app/patient/info/by-multiple',
  // 我的页面家人，优惠券，收藏数量统计
  MINE_HEAD_COUNT: '/app/user/getUserHead',
  // 我的页面活动数量统计
  MINE_ACTIVITY_COUNT: '/app/channel/activity/count',
  // 同行人保存，查看，删除
  COMPANION_LIST: '/app/declaration/peer/list',
  COMPANION_SAVE: '/app/declaration/peer/save',
  COMPANION_DEL: '/app/declaration/peer/delete',
  COMPANION_INFO: '/app/declaration/peer/info',
};
