import Vue from 'vue';
import VueI18n from 'vue-i18n';
import locales from '@/utils/locales';

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'zh',
  fallbackLocale: 'zh',
  messages: locales,
  silentTranslationWarn: true,
  missing: (locale, key) => {
    console.warn(`i18n key ${key} 不存在`);
  },
});

export default i18n;
