export default class Event {
  /**
   * 订阅事件
   */
  static subscribe(key, call) {
    uni.$on(key, call);
  }

  /**
   * 订阅单次事件，接收到事件后自动移除监听
   */
  static subscribeOnce(key, call) {
    uni.$once(key, call);
  }

  /**
   * 取消订阅事件
   */
  static unsubscribe(key, call) {
    uni.$off(key, call);
  }

  /**
   * 发送事件
   */
  static post(key, value) {
    uni.$emit(key, value);
  }
}
