import _ from 'underscore';

class wxHttp {
  /**
   * 去除空的字段
   * @param obj
   */
  static dealElement(obj) {
    if (typeof obj === 'array' || typeof obj === 'string') {
      return obj;
    } else if (typeof obj === 'object') {
      let param = {};
      if (obj === null || obj === undefined || obj === '') return param;
      for (let key in obj) {
        if (obj[key] !== null && obj[key] !== undefined && !_.isFunction(obj[key])) {
          param[key] = obj[key];
        }
      }
      return param;
    } else {
      return obj;
    }
  }

  static processDateOffset(data) {
    const st = data?.st;
    if ((st && st !== '') || typeof st === 'number') {
      let diff = st - new Date().getTime();
      uni?.setStorage?.({ key: 'TIME_OFFSET', data: diff });
    }
  }
}

export default wxHttp;
