import dayjs from 'dayjs';
import qs from 'qs';
import { STORAGE_KEY } from './UFHStorage';
import find from 'lodash.find';
import { PAGES } from './constants';
import { navigateTo, redirectToCheckLogin } from './index';
import { parseUrl } from "query-string";
/**
 * 按指定长度为数组分行
 *
 * @param arr 待分行单维数组
 * @param size 指定长度
 * @returns 二维数组
 *
 * @author gaoxueying
 */
export function splitArrayBySize(arr, size) {
    return arr.reduce((lines, n, i) => {
        let line = lines[Math.floor(i / size)];
        if (!line) {
            line = lines[Math.floor(i / size)] = [];
        }
        line.push(n);
        return lines;
    }, []);
}
// 距离标量及换算（单位：米）
const DISTANCE_UNIT_SCALES = {
    km: 1000,
    m: 1,
    cm: 0.01,
};
/**
 * 格式化距离显示
 *
 * @param value 数值（单位：米）
 * @param unit 单位
 * @param fixed 定点小数
 * @returns 距离字符串
 *
 * @author gaoxueying
 */
export function formatDistance(value, unit = 'm', fixed = 0) {
    return (value / (DISTANCE_UNIT_SCALES[unit] ?? 1)).toFixed(fixed);
}
export function maskMobile(str) {
    return str.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}
export function formatDatetime(date, format = 'YYYY-MM-DD HH:mm') {
    if (!date) {
        return '';
    }
    return dayjs(date).format(format);
}
export function niceTry(fn, defaultValue) {
    try {
        return fn();
    }
    catch (e) {
        return defaultValue;
    }
}
const VersionTargetOffset = {
    major: 1,
    minor: 2,
    patch: 3,
};
export function getVersionName(target) {
    const version = process.env.VUE_APP_VERSION;
    return target ? version.split('.').slice(0, VersionTargetOffset[target]).join('.') : version;
}
export const sleep = (s, rst) => new Promise((resolve) => {
    setTimeout(() => resolve(rst), s);
});
export const getGlobalData = () => {
    return {
        user: wx.getStorageSync('global_user_info'),
    };
};
export const clearGlobalUserData = () => {
    wx.removeStorageSync('global_user_info');
};
export async function reLaunch() {
    wx.reLaunch({
        url: '/pages/loading/main',
    });
}
export const createUrl = (url, query) => {
    const search = qs.stringify(query, {
        encode: false,
        skipNulls: true,
    });
    if (search) {
        url += (url.includes('?') ? '&' : '?') + search;
    }
    return url;
};
export const deepClone = (obj) => JSON.parse(JSON.stringify(obj));
let _DICT = null;
export function initDICT(dict) {
    Object.values(dict).forEach((item) => {
        if (Array.isArray(item)) {
            item.forEach((o) => {
                o.langDataObj = niceTry(() => JSON.parse(o.langData));
            });
        }
    });
    _DICT = dict;
}
export const DICT = () => {
    if (_DICT == null) {
        // 一开始为null
        const dict = wx.getStorageSync(STORAGE_KEY.GLOBAL_DATA_DICT); // 本地存储中获取 GLOBAL_DATA_DICT 数据
        Object.values(dict).forEach((item) => {
            if (Array.isArray(item)) {
                item.forEach((o) => {
                    // niceTry函数就是返回回调函数的执行结果
                    o.langDataObj = niceTry(() => JSON.parse(o.langData)); // 把数组元素对象的JSON格式属性langData转化为对象，新增到langDataObj属性上
                });
            }
        });
        _DICT = dict;
    }
    // 返回处理后的GLOBAL_DATA_DICT
    return _DICT ?? {};
};
export const searchHistorySize = 15;
export const DICT_KEY = {
    EPISODE_SEARCH_TYPE: 'EPISODE_SEARCH_TYPE',
    LANGUAGE: 'LANGUAGE',
    PATIENT_RELATION: 'PATIENT_RELATION',
    HIS_GUARDIAN_RELATION: 'HIS_GUARDIAN_RELATION',
    HIS_COUNTRIES: 'HIS_COUNTRIES',
    RESERVE_SEARCH_TYPE: 'RESERVE_SEARCH_TYPE',
    HIS_RESERVE_STATUS: 'HIS_RESERVE_STATUS',
    HIS_RESERVE_TYPE: 'HIS_RESERVE_TYPE',
    RESERVE_CANCEL_REMARK: 'RESERVE_CANCEL_REMARK',
    DOCTOR_NATIONALITY: 'DOCTOR_NATIONALITY',
    HIS_NATIONALITY: 'HIS_NATIONALITY',
    RESERVE_TYPE: 'RESERVE_TYPE',
    DOCUMENT: 'DOCUMENT',
    HIS_CONTACT_RELATION: 'HIS_CONTACT_RELATION',
    HIS_GENDER: 'HIS_GENDER',
    LBS: 'LBS',
    HIS_LANGUAGE: 'HIS_LANGUAGE',
};
export function getI18nLabel(entry, lang) {
    // TODO:[jiawei] 如果 lang 的值就是 zh_cn 却被识别为 en_us
    return entry?.langDataObj?.[lang === 'zh' ? 'zh_CN' : 'en_US']?.dict_name ?? entry?.dictName ?? null;
    //  return entry?.langDataObj?.[lang === 'zh' ? 'zh_CN' : lang]?.dict_name ?? entry?.dictName ?? null;
}
export function getDictI18nLabel(key, code, lang) {
    const dict = DICT()?.[key];
    const entry = find(dict, ['dictCode', String(code)]);
    return getI18nLabel(entry, lang);
}
export const padSlash = (s) => (s.startsWith('/') ? s : '/' + s);
export const openPage = async (url, param, opt) => {
    if (opt?.disabled) {
        return;
    }
    console.log('url:', url);
    console.log('param:', param);
    console.log('opt:', opt);
    if (typeof url === 'number' && Number.isSafeInteger(url) && url < 0) {
        if (getCurrentPages().length > 0 - url) {
            await wx.navigateBack({
                delta: 0 - url,
            });
        }
        else {
            await wx.switchTab({
                url: PAGES.TABS.HOME,
            });
        }
        return;
    }
    if (typeof url === 'object') {
        if (url.locationType === 1) {
            // padSlash：确保url以/开头
            url = padSlash(url.wechatMiniAppPath);
        }
        else if (url.locationType === 2) {
            url = url.webLinkUrl ?? '';
        }
        else if (url.locationType === 3) {
            url = `weixin://${url.wechatMiniAppAppId}${padSlash(url.wechatMiniAppPath ?? '')}`;
        }
    }
    // 如果需要检查登录 且 用户也没有登录
    if ((opt?.loginRequired || opt?.patientRequired) && wx.getStorageSync(STORAGE_KEY.GLOBAL_USER_INFO)?.isLogin !== true) {
        if (typeof url === 'string' && url.startsWith('/')) {
            // 本地存储重定向url，
            wx.setStorageSync(STORAGE_KEY.REDIRECT_URL, createUrl(url, param));
        }
        // 导航到注册页面（navigateTo方法会判断最终是去注册还是登录页面）
        if (opt.redirect) {
            // 有些页面需要在跳转到登录或注册时候就需要重定向（例如从外部打开某个页面，此时页面已经创建）
            redirectToCheckLogin(PAGES.REGISTER.MAIN);
        }
        else {
            navigateTo(PAGES.REGISTER.MAIN);
        }
        return;
    }
    // 如果需要检查是否有患者
    if (opt?.patientRequired) {
        await wx.navigateTo({
            url: createUrl(`/pages/patient/choice/index`, {}),
        });
        return;
    }
    // 经过上面的步骤，url都变成string type了
    if (typeof url === 'string') {
        // @ts-ignore
        const { protocol = 'weixin', host, path, relative } = parseUrl(url);
        // 默认weixin
        if (protocol === 'weixin') {
            if (host) {
                // 没有协议
                wx.navigateToMiniProgram({
                    appId: host,
                    path: createUrl(relative ?? '', param),
                    extraData: {},
                    envVersion: process.env.VUE_APP_ENV !== 'prod' || process.env.VUE_APP_ENV !== 'production' ? 'trial' : 'release',
                });
            }
            else {
                // 不是首页、我的页
                if (url === '/pages/mine/main' || url === PAGES.TABS.HOME) {
                    wx.switchTab({
                        url: createUrl(url, param),
                    });
                }
                else {
                    // 大多走这步 ==============
                    wx.navigateTo({
                        url: createUrl(url, param),
                    });
                }
            }
        }
        else if (protocol === 'https' || protocol === 'http') {
            wx.navigateTo({
                url: createUrl(PAGES.GLOBAL.WEBVIEW, { url: encodeURIComponent(createUrl(url, param)) }),
            });
        }
    }
};
export async function copyToClip(data) {
    await wx.setClipboardData({
        data,
    });
}
export async function goRabbitSupport() {
    await wx.navigateToMiniProgram({
        appId: 'wx8abaf00ee8c3202e',
        path: '',
        extraData: {
            id: process.env.VUE_APP_MP_TXC_PRODUCT_ID,
            customData: {},
        },
        envVersion: process.env.VUE_APP_ENV !== 'prod' || process.env.VUE_APP_ENV !== 'production' ? 'trial' : 'release',
    });
}
export const now = () => new Date().getTime();
export const ICON = {
    FORWARD: '/static/imgs/forward_icon.svg',
    DROPDOWN: '/static/imgs/dropdown_icon.svg',
};
export const stringOf = (s) => {
    if (typeof s === 'string') {
        return s;
    }
    else if (typeof s === 'number' || typeof s === 'boolean') {
        return String(s);
    }
    return '';
};
export const COLOR = {
    PRIMARY: '#007c95',
    NORMAL: '#333333',
    // --primary-normal-hex: #333333;
    // --primary-color-hex: #3478F6;
    // --secondary-color-hex: #4DC0FC;
    // --success-color-hex: #23C58A;
    // --error-color-hex: #FF817D;
    // --disable-color-hex: #999999;
    // --disable-color-rgb: 153, 153, 153;
    // --primary-tint-color-hex: #5499FC;
    // --primary-color-rgb: 52, 120, 246;
    // --primary-100-color-hex: #E8F3FE;
    // /* --primary-100-color-hex: #F7F8FC; */
    // /* --primary-100-color-hex: #F3F5FB; */
    // --gray-100-color-hex: #F7F8FC;
    // --gray-200-color-hex: #D8D8D8;
    // --gray-300-color-hex: #b3b3b3;
    // --gray-400-color-hex: #999999;
    // --gray-500-color-hex: #6E6E6E;
    // --gray-700-color-hex: #474747;
    // --gray-800-color-hex: #333333;
    // --gray-900-color-hex: #111111;
};
export const ModelStyle = {
    confirmColor: COLOR.PRIMARY,
    cancelColor: COLOR.NORMAL,
};
