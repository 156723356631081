import { STORAGE_KEY } from './UFHStorage';
import Store from './store';
import { getSystemInfoSync } from './index';
import _$bus from '../utils/EventBus';
import { EVENT_KEYS } from '@/utils/constants';
import i18n from '../i18n';

/*
 * 设置tabBar
 * */
export const setTabBar = (data = []) => {
  data.forEach((item) => {
    const { index, text } = item;
    uni.setTabBarItem({
      index,
      text,
      success: (result) => {
        // console.log('setTabBarItem:', result)
      },
      fail: (error) => {
        // console.log('setTabBarItem:', error)
      },
    });
  });
};

/*
 * 获取storage里存储的语言
 * */
export const getStorageLocale = () => {
  let localeCode = uni.getStorageSync(STORAGE_KEY.LANGUAGE);
  return localeCode === 'zh_CN' ? 'zh' : localeCode; // 兼容老的在storage里存的code
};

/*
 * 获取系统语言
 * */
export const getSystemLocale = () => {
  return getSystemInfoSync('language').slice(0, 2);
};

/*
 * 获取语言code
 * */
export const getLocalLocale = () => {
  return getStorageLocale() || (getSystemLocale() === 'zh' ? 'zh' : 'en');
};

/**
 * 是否是中文
 * @returns {boolean}
 */
export const isZh = () => {
  return getLocalLocale() === 'zh';
};

/*
 * 设置语言（store和本地localStorage）
 *   langCode: 语言code，
 *   callback：设置成功回调，
 *   shouldPostLang：是否需要记录当前登录用户使用的语言
 * */
export const setLocalLocale = function (langCode, callback, shouldPostLang = true) {
  if (langCode === undefined) {
    langCode = getLocalLocale();
  }
  this._i18n.locale = langCode;
  uni.setStorageSync(STORAGE_KEY.LANGUAGE, langCode);
  Store.commit('common/setlang', { lang: langCode });
  callback && callback(langCode);

  // 发送语言变更通知
  _$bus.post(EVENT_KEYS.LANGUAGE_CHANGED, langCode);
  this.setPageTitle && this.setPageTitle(); // 设置页面标题
  let tabBarConfig = [
    // eslint-disable-next-line @intlify/vue-i18n/no-missing-keys
    { index: 0, text: i18n.t('i18n_kmp6z957_1651741146217_info') },
    // eslint-disable-next-line @intlify/vue-i18n/no-missing-keys
    { index: 1, text: i18n.t('i18n_yuxkvns5_1651741146207_info') },
  ];
  setTabBar(tabBarConfig);
};
