<template>
  <text class="iconfont" :class="'ufh-icon-' + name + ' '+ classStr" :style="propsStyle" aria-hidden="true" @click="onClick" />
</template>

<script>
/**
 * iconfont 图标
 * @description 用于展示 iconfont 图标
 * @property {String} name 图标图案，参考示例
 * @property {Number} size 图标大小
 * @property {String} color 图标颜色
 * @event {Function} click 点击 Icon 触发事件
 */
export default {
  name: 'SpIconNew',
  props: {
    name: {
      type: String,
      default: '',
    },
    size: {
      type: [String, Number],
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    classStr: {
      type: String,
      default: ''
    }
  },
  computed: {
    propsStyle() {
      let styleStr = '';
      if (this.size) {
        let sizeStr = this.size;
        try {
          sizeStr = `${parseInt(this.size)}rpx`;
        } catch (e) {}
        styleStr += `--sp-font-size:${sizeStr};`;
      }
      if (this.color) {
        styleStr += `--sp-font-color:${this.color};`;
      }
      return styleStr;
    },
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.iconfont {
  font-size: var(--sp-font-size, inherit);
  color: var(--sp-font-color);
  display: inline-block;
}
</style>
