const STORAGE_KEY = {
  LANGUAGE: 'language',
  OPEN_ID: 'openId',
  TOKEN: 'token',
  USER_ID: 'userId',
  PATIENT_ADD_INFO: 'patientAddInfo',
  CLINICS: 'clinics',
  APPOINTMENT: 'appointment',
  DATA_DICT: 'dataDict',
  DATA_DICT_V2: 'dataDictV2', // 新版本的数据字典
  APPT_DOCTOR_INFO: 'appt_doctor_info', // 所选预约医生的信息
  RECENT_PHONE_NUMBER: 'recentPhoneNumber', // 最近手机
  RECENT_PHONE_AREA_CODE: 'recentPhoneAreaCode', // 最近手机区号
  LOGIN_PHONE: 'loginPhone', // 登录的手机号
  LOGIN_PHONE_AREA_CODE: 'loginPhoneAreaCode', // 登录的手机号区号
  REDIRECT_URL: 'redirectUrl', // 登录后要重定向到的页面及参数
  LOGINED_ACTION: 'loginedAction', // 登录后跳转到相应页面后的动作名（{name: '动作名', argument: 参数}）
  BLOCK_REQUEST: 'blockRequest', // 锁定请求
  APPT_INFO: 'apptInfo', // 预约信息
  PROMOTER_PARAM: 'promoterParam', // 推广信息的二维码参数
  SUB_MSG_LIST: 'subMsgList', // 授权列表
  REPORT_URL: 'reportUrl', // 报告的url
  PATIENT_HEAD_IMAGE: 'patientHeadImage', // 就诊人上传的头像
  CONSTANT: 'constant', // 就诊人上传的头像
  FACE_AUTHORIZED: 'FACE_AUTHORIZED', // 是否跳过人脸核身  1不跳过 0跳过
  WEBVIEW_URL: 'webviewUrl', // webview加载的url
  COUPON_DETAIL: 'COUPON_DETAIL', // 优惠券详情,
  FIELD_FORM: 'fieldForm', // 新建家人表单保存
  FIELD_FORM_GUARDIAN: 'fieldFormGuardian', // 新建家人-监护人表单保存
  COUNTRIES: 'countries', // 所有国家
  REPORTS: 'reports', // 选择报告
  LOCATIONS: 'LOCATIONS',
  GLOBAL_USER_INFO: 'global_user_info',
  GLOBAL_USER_LOCATION: 'global_user_location',
  GLOBAL_DATA_DICT: 'global_data_dict',
  LAST_UPDATE_SESSION_KEY_TIME: 'last_update_session_key_time',
  NOTICE_READS: 'notice-reads',
  SEARCH_HISTORY: 'search_history',
  CITY_CODE: 'city_code', // 城市ID
  IGNORE_CITY_CODE: 'ignore_city_code',
  AUTHORIZED_SENSITIVE_REPORT: 'AUTHORIZED_SENSITIVE_REPORT', // 授权过的敏感报告
  /** 优惠券发放id路径参数，CRM传递 */
  COUPON_SEND_ID: 'couponStr',
  ADTAG: 'ADTAG', // 推广值
  ISCONSULT: 'isConsult', // 从第三方小程序跳来的
};

class UFHStorage {
  static setStorageData(key, data) {
    uni.setStorageSync(key, data);
  }

  static getStorageData(key) {
    return uni.getStorageSync(key);
  }

  static removeStorageData(key) {
    uni.removeStorageSync(key);
  }

  static clearStorage(key) {
    uni.clearStorage();
  }

  static updateGlobalUserInfo(data = {}) {
    if (data.token) {
      UFHStorage.setStorageData(STORAGE_KEY.TOKEN, data.token);
    }

    const preUserInfo = UFHStorage.getStorageData(STORAGE_KEY.GLOBAL_USER_INFO);
    const newUserInfo = Object.assign(preUserInfo || {}, data);
    UFHStorage.setStorageData(STORAGE_KEY.GLOBAL_USER_INFO, newUserInfo);
  }

  static resetLoginStorageData() {
    UFHStorage.removeStorageData(STORAGE_KEY.USER_ID);
    UFHStorage.removeStorageData(STORAGE_KEY.TOKEN);
    UFHStorage.removeStorageData(STORAGE_KEY.OPEN_ID);
    UFHStorage.removeStorageData(STORAGE_KEY.LOGIN_PHONE);
    UFHStorage.removeStorageData(STORAGE_KEY.LOGIN_PHONE_AREA_CODE);
  }
}

export { STORAGE_KEY, UFHStorage };

export default UFHStorage;
