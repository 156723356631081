/**
 * 授权类型
 */
export var AUTH_TYPE;
(function (AUTH_TYPE) {
    /**
     * 自动授权
     */
    AUTH_TYPE[AUTH_TYPE["AUTO"] = 1] = "AUTO";
    /**
     * 手动授权
     */
    AUTH_TYPE[AUTH_TYPE["MANUAL"] = 2] = "MANUAL";
})(AUTH_TYPE || (AUTH_TYPE = {}));
/**
 * 主次证件
 */
export var CERTIFICATE_MAIN;
(function (CERTIFICATE_MAIN) {
    CERTIFICATE_MAIN["MAIN"] = "1";
    CERTIFICATE_MAIN["OTHER"] = "0";
})(CERTIFICATE_MAIN || (CERTIFICATE_MAIN = {}));
/**
 * 申报状态
 */
export var DECLARATION_STATUS;
(function (DECLARATION_STATUS) {
    // 未申报
    DECLARATION_STATUS[DECLARATION_STATUS["UN_APPLY"] = 0] = "UN_APPLY";
    // 已申报
    DECLARATION_STATUS[DECLARATION_STATUS["APPLY"] = 1] = "APPLY";
})(DECLARATION_STATUS || (DECLARATION_STATUS = {}));
/**
 * 就诊人关系
 */
export var UFH_RELATION_TYPE;
(function (UFH_RELATION_TYPE) {
    UFH_RELATION_TYPE[UFH_RELATION_TYPE["SELF"] = 1] = "SELF";
    UFH_RELATION_TYPE[UFH_RELATION_TYPE["SPOUSE"] = 2] = "SPOUSE";
    UFH_RELATION_TYPE[UFH_RELATION_TYPE["PARENT"] = 3] = "PARENT";
    UFH_RELATION_TYPE[UFH_RELATION_TYPE["CHILDREN"] = 4] = "CHILDREN";
    UFH_RELATION_TYPE[UFH_RELATION_TYPE["RELATIVES"] = 5] = "RELATIVES";
    UFH_RELATION_TYPE[UFH_RELATION_TYPE["GUARDIAN"] = 6] = "GUARDIAN";
    UFH_RELATION_TYPE[UFH_RELATION_TYPE["OTHER"] = 7] = "OTHER";
})(UFH_RELATION_TYPE || (UFH_RELATION_TYPE = {}));
export var PATIENT_AUTH;
(function (PATIENT_AUTH) {
    let AUTH_CODE;
    (function (AUTH_CODE) {
        // 就诊并评价.
        AUTH_CODE["EPISODE"] = "EPISODE";
        // 报告
        AUTH_CODE["REPORT"] = "REPORT";
        // 支付
        AUTH_CODE["PAYMENT"] = "PAYMENT";
    })(AUTH_CODE = PATIENT_AUTH.AUTH_CODE || (PATIENT_AUTH.AUTH_CODE = {}));
    let AUTH_STATUS;
    (function (AUTH_STATUS) {
        /**
         * 未授权
         */
        AUTH_STATUS[AUTH_STATUS["UN_AUTH"] = 1] = "UN_AUTH";
        /**
         * 授权中
         */
        AUTH_STATUS[AUTH_STATUS["AUTHING"] = 2] = "AUTHING";
        /**
         * 已授权
         */
        AUTH_STATUS[AUTH_STATUS["AUTHED"] = 3] = "AUTHED";
        /**
         * 拒绝
         */
        AUTH_STATUS[AUTH_STATUS["REFUSED"] = 4] = "REFUSED";
        /**
         * 超时
         */
        AUTH_STATUS[AUTH_STATUS["TIMEOUT"] = 5] = "TIMEOUT";
        /**
         * 无效
         */
        AUTH_STATUS[AUTH_STATUS["INVALID"] = 6] = "INVALID";
    })(AUTH_STATUS = PATIENT_AUTH.AUTH_STATUS || (PATIENT_AUTH.AUTH_STATUS = {}));
})(PATIENT_AUTH || (PATIENT_AUTH = {}));
// <!--"-1"代表没有号,0代表可约,1代表约满-->
export var SCHEDULE_STATUS;
(function (SCHEDULE_STATUS) {
    /**
     * 无号
     */
    SCHEDULE_STATUS["NO"] = "-1";
    /**
     * 可约
     */
    SCHEDULE_STATUS["YES"] = "0";
    /**
     * 约满
     */
    SCHEDULE_STATUS["FULL"] = "1";
})(SCHEDULE_STATUS || (SCHEDULE_STATUS = {}));
export var RESERVE_TYPE;
(function (RESERVE_TYPE) {
    /**
     * 自助预约
     */
    RESERVE_TYPE[RESERVE_TYPE["APPT"] = 1] = "APPT";
    /**
     * 电话预约
     */
    RESERVE_TYPE[RESERVE_TYPE["PHONE"] = 2] = "PHONE";
    /**
     * 客服预约（--> 改为在线客服）
     */
    RESERVE_TYPE[RESERVE_TYPE["SERVICE"] = 3] = "SERVICE";
})(RESERVE_TYPE || (RESERVE_TYPE = {}));
/**
 * 预约状态
 */
export var RESERVE_STATUS;
(function (RESERVE_STATUS) {
    /**
     * 已预约
     */
    RESERVE_STATUS["P"] = "P";
    /**
     * 已改约
     */
    RESERVE_STATUS["T"] = "T";
    /**
     * 已达到
     */
    RESERVE_STATUS["A"] = "A";
    /**
     * 已取消
     */
    RESERVE_STATUS["X"] = "X";
    /**
     * 爽约(已取消)
     */
    RESERVE_STATUS["N"] = "N";
    /**
     * 暂停预约
     */
    RESERVE_STATUS["H"] = "H";
    /**
     * 关闭预约
     */
    RESERVE_STATUS["C"] = "C";
    /**
     * 已到达，未看诊
     */
    RESERVE_STATUS["S"] = "S";
})(RESERVE_STATUS || (RESERVE_STATUS = {}));
/**
 * 预约类型
 */
export var RESERVE_SEARCH_TYPE;
(function (RESERVE_SEARCH_TYPE) {
    /**
     * 门诊
     */
    RESERVE_SEARCH_TYPE[RESERVE_SEARCH_TYPE["MZ"] = 1] = "MZ";
    /**
     * 疫苗
     */
    RESERVE_SEARCH_TYPE[RESERVE_SEARCH_TYPE["YM"] = 2] = "YM";
    /**
     * 体检
     */
    RESERVE_SEARCH_TYPE[RESERVE_SEARCH_TYPE["TJ"] = 3] = "TJ";
    /**
     * 移民体检
     */
    RESERVE_SEARCH_TYPE[RESERVE_SEARCH_TYPE["YMTJ"] = 4] = "YMTJ";
    /**
     * 核酸
     */
    RESERVE_SEARCH_TYPE[RESERVE_SEARCH_TYPE["HS"] = 5] = "HS";
})(RESERVE_SEARCH_TYPE || (RESERVE_SEARCH_TYPE = {}));
