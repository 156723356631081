/**
 * Created by coeus on 17/3/19.
 */

/**
 * 标准数字格式检测
 */
const NUMBER_REGEX = /[+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*)(?:[eE][+-]?\d+)?/;

class ObjectUtil {
  static isEmpty(val) {
    if (val == null) {
      return true;
    }

    switch (typeof val) {
      case 'string':
        return ObjectUtil.trim(val).length === 0;
      case 'number':
        return false;
      case 'object':
        if (val.constructor === Array) {
          return val.length === 0;
        } else if (val.constructor === Object) {
          if (val == null) {
            return true;
          } else {
            for (let name in val) {
              return false;
            }
            return true;
          }
        } else {
          return val == null;
        }
      case 'array':
        return val.length === 0;
      case 'function':
        return false;
      default:
        return true;
    }
  }

  /**
   * 去除空格
   * @param {string} text 待处理的字符串
   * @returns {string} 处理过得字符
   */
  static trim(text) {
    if (typeof text === 'string') {
      return text.replace(/^\s*|\s*$/g, '');
    } else {
      return text;
    }
  }

  /**
   * 格式化数字，保留指定位数
   * @export
   * @param {(number | string)} num 需要格式化的数据,或形如 '12,000'
   * @param {number} [digits=2] 需要保留的小数位数
   * @returns 格式化后的数字
   */
  static trimNum(num, digits = 2) {
    let n = 0;

    if (typeof num === 'number') {
      n = num;
    } else if (typeof num === 'string') {
      const parsed = num.replace(/,/g, '');

      // 检测形如'-1.2345'的标准数字格式
      if (NUMBER_REGEX.test(parsed)) {
        n = parseFloat(parsed);
        if (isNaN(n)) return 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }

    const multi = Math.pow(10, digits);
    return Math.round(n * multi) / multi;
  }

  static trimNumber(num, digits = 2) {
    let n = 0;
    if (isNaN(Number(num)) || (!num && num !== 0)) return 0;

    if (typeof num === 'number') {
      n = num;
    } else if (typeof num === 'string') {
      const parsed = num.replace(/,/g, '');

      // 检测形如'-1.2345'的标准数字格式
      if (NUMBER_REGEX.test(parsed)) {
        n = parseFloat(parsed);
        if (isNaN(n)) return 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }

    const multi = Math.pow(10, digits);
    const _sign = n < 0 ? -1 : 1;
    const precision = (n * multi * 100).toString().replace(/[+-.]/g, '').length;

    return (Math.round((n * multi * _sign).toPrecision(precision)) / multi) * _sign;
  }
}

export default ObjectUtil;
export { ObjectUtil };
