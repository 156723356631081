/**
 * Created by coeus on 17/3/21.
 */

import { ObjectUtil } from './ObjectUtil';
import UFHStorage, { STORAGE_KEY } from './UFHStorage';
import Store from './store';
import _ from 'underscore';

class DataDictHelper {
  /**
   * 设置数据字典
   * @param {object} dataDict 数据字典
   * @return {void}
   */
  static setDataDict(dataDict) {
    UFHStorage.setStorageData(STORAGE_KEY.GLOBAL_DATA_DICT, dataDict);
  }

  /**
   * 设置常量
   * */
  static seConstant(data) {
    UFHStorage.setStorageData(STORAGE_KEY.CONSTANT, data);
  }

  /**
   * 获取数据字典
   * @return {object} 数据字典对象
   */
  static getV2DataDict() {
    return UFHStorage.getStorageData(STORAGE_KEY.DATA_DICT_V2) || {};
  }

  /**
   * 获取数据字典
   * @return {object} 数据字典对象
   */
  static getDataDict() {
    return UFHStorage.getStorageData(STORAGE_KEY.GLOBAL_DATA_DICT) || {};
  }

  /**
   * 根据指定 key 获取数据字典
   * @returns {undefined}
   */
  static getDataDictArrayWithKey(key) {
    // if (ObjectUtil.isEmpty(DataDictHelper.getDataDict())) {
    //     return null;
    // }
    let dataDict = DataDictHelper.getDataDict();
    let result = dataDict[key] || [];
    result.forEach((item) => {
      if (item.langData) {
        try {
          item.langData = JSON.parse(item.langData);
        } catch (e) {}
      }
    });
    return result;
  }

  /**
   * 将数组转换成对象.
   * @param key
   * @param paths
   * @returns {{}|null}
   */
  static getDataDictObjWithKeyPath(key, paths) {
    // if (ObjectUtil.isEmpty(DataDictHelper.getDataDict())) {
    //     return {};
    // }
    let dataDictWithKey = DataDictHelper.getDataDictArrayWithKey(key);

    const targetObj = {};
    for (const obj of dataDictWithKey) {
      const key = obj[paths]; //_.get(obj, Array.isArray(paths) ? paths : [paths]);
      // if (obj.langData) {
      //     try {
      //         obj.langData = JSON.parse(obj.langData);
      //     } catch (e) {
      //         console.log('e:',e)
      //     }
      // }
      targetObj[key] = obj;
    }

    return targetObj;
  }

  /**
   * 移除数据字典
   * @return {void}
   */
  static clearV2DataDict() {
    UFHStorage.removeStorageData(STORAGE_KEY.DATA_DICT_V2);
  }

  /**
   * 移除数据字典
   * @return {void}
   */
  static clearDataDict() {
    UFHStorage.removeStorageData(STORAGE_KEY.GLOBAL_DATA_DICT);
  }

  /**
   * 通过key查找数据字典
   * @param {string} key 数据字典的KEY
   * @returns {object} 根据KEY取得的VALUE
   */
  static queryV2DataDictWithKey(key) {
    if (ObjectUtil.isEmpty(DataDictHelper.getV2DataDict())) {
      return null;
    }
    let dataDict = DataDictHelper.getV2DataDict();
    return dataDict[key];
  }

  /**
   * 通过key查找数据字典
   * @param {string} key 数据字典的KEY
   * @returns {object} 根据KEY取得的VALUE
   */
  static queryDataDictWithKey(key) {
    if (ObjectUtil.isEmpty(DataDictHelper.getDataDict())) {
      return null;
    }
    let dataDict = DataDictHelper.getDataDict();
    return dataDict[key];
  }

  /**
   * 通过key查找数据字典
   * @param {string} key 数据字典的KEY
   * @param {string} value 数据字典的KEY
   * @returns {object} 根据KEY取得的VALUE
   */
  static queryNameWithValueV2(key, value) {
    let dataDict = DataDictHelper.queryV2DataDictWithKey(key);
    if (_.isEmpty(dataDict)) {
      return '';
    }
    const filters = dataDict.filter((v) => v.code === value);
    if (_.isEmpty(filters)) {
      return '';
    } else {
      // 是否是英文
      const isEnUs = Store.getters?.['common/isEnUs'];
      return filters[0][isEnUs ? 'nameEn' : 'nameCn'];
    }
  }

  // 常量值
  static KEYS = {
    CARD_TYPE: 'ID_TYPE', // 证件类型
    CHINESE_2: 'CHINESE_2',
    PREFER_LANGUAGE: 'PREFER_LANGUAGE', // 惯用语言
    RELATIVE: 'RELATIVE', // 紧急联系人关系
    MARRIAGE: 'MARRIAGE', // 婚姻状态
    PAYMENT_METHOD: 'paymentmethod', // 支付方式
    PHONE: 'phone',
    HOME_NAV: 'homeNav',
    HOSPITAL: 'hospital',
    HMP_PHONE: 'hmpPhone',
    MINI_PROGRAM_ONLINE_SERVICE_IS_OPEN: 'MINI_PROGRAM_ONLINE_SERVICE_IS_OPEN',
    ROOT_AUTH_RELATION: 'ROOT_AUTH_RELATION', // 家庭关系
    GENDER: 'SEX', // 性别
    ROOT_AUTH_CODE: 'ROOT_AUTH_CODE', // 授权code
    GUARDIAN_RELATION: 'GUARDIAN_RELATION', // 监护人与家人关系
    DOCUMENT: 'DOCUMENT',
    HIS_RESERVE_SERVICE_TYPE: 'HIS_RESERVE_SERVICE_TYPE',
    LBS: 'LBS',
    // 国籍
    HIS_NATIONALITY: 'HIS_NATIONALITY',
    // 国家
    HIS_COUNTRIES: 'HIS_COUNTRIES',
    RESERVE_TYPE: 'RESERVE_TYPE',
    LANGUAGE: 'LANGUAGE', // 语言
    RESERVE_CANCEL_REMARK: 'RESERVE_CANCEL_REMARK', // 预约取消原因
    HIS_RESERVE_STATUS: 'HIS_RESERVE_STATUS', // his 预约状态
    EPIDEMIOLOGY_SURVEY_STATUS: 'EPIDEMIOLOGY_SURVEY_STATUS', // 流调状态
    PATIENT_RELATION: 'PATIENT_RELATION', // 家人与患者关系
  };
}

export default DataDictHelper;
export { DataDictHelper };
