//@ts-nocheck
import dayjs from 'dayjs';
import _ from 'underscore';
import DataDictHelper from './DataDictHelper';
import UFHStorage, { STORAGE_KEY } from './UFHStorage';
import HttpHelper, { apiService } from '@/utils/http';
import { APP_MSG_VISIT_ENUM, PAGES } from '@/utils/constants';
import { getLocalLocale } from '@/utils/i18n';
import i18n from '@/i18n';
import { UFH_RELATION_TYPE } from '@/constants/enum.constants';
import { initDICT } from './misc';
/*
 * 是否是有效对象
 * */
export function isValidObject(data) {
    const isObject = Object.prototype.toString.call(data) === '[object Object]';
    if (isObject) {
        return Object.getOwnPropertyNames(data).length > 0;
    }
    return false;
}
export function encodeSearchParams(obj) {
    // 将一个对象 转换成get 请求需要的格式的字符串
    const params = [];
    Object.keys(obj).forEach((key) => {
        let value = obj[key];
        // 如果值为undefined我们将其置空
        if (typeof value === 'undefined') {
            value = '';
        }
        // 对于需要编码的文本（比如说中文）我们要进行编码
        params.push([key, value].join('='));
    });
    return params.join('&');
}
/*
 * 获取页面传递的query 的value值
 * */
export function getQuery(key) {
    // 用前先把this bind过去
    // 如果不传key 则直接返回query对象 如果传递 key 则获取相应value
    // @ts-ignore
    const query = this?.$mp?.query;
    if (key) {
        return query[key];
    }
    else {
        return query;
    }
}
export function isObject(obj) {
    const type = typeof obj;
    return type === 'function' || (type === 'object' && !!obj);
}
/*
 * 组合path
 * */
export function combinePath(path) {
    if (!path.startsWith('/pages/')) {
        // 简短调用
        if (path.startsWith('pages/')) {
            path = `/${path}`;
        }
        else {
            path = `/pages/${path}/main`;
        }
    }
    return path;
}
/*
 * tab选项卡切换
 * */
export function switchTab(data) {
    if (isValidObject(data)) {
        // 原生调用
        uni.switchTab(data);
        return;
    }
    data = combinePath(data);
    uni.switchTab({
        // 全路径调用
        url: data,
    });
}
/*
 * 跳转到某个页面
 * */
export function navigateTo(data, query) {
    // 跳转路由的特殊情况：
    // 如果传入的路由是想要进入REGISTER注册页面，则判断最终是去REGISTER注册页面 还是 LOGIN登录页面
    if (data.url === PAGES.REGISTER.MAIN || data === PAGES.REGISTER.MAIN) {
        updateUserInfo().then((result) => {
            if (result.isReg) {
                uni.navigateTo({ url: PAGES.LOGIN });
            }
            else if (!result.isReg) {
                uni.navigateTo({ url: PAGES.REGISTER.MAIN });
            }
        });
        return;
    }
    // 如果是对象形式
    if (isValidObject(data)) {
        uni.navigateTo(data);
        return;
    }
    // 如果是字符串形式
    data = combinePath(data);
    // 当传递query 参数时 拼接进url中
    if (isValidObject(query)) {
        const queryStr = encodeSearchParams(query);
        data = `${data}?${queryStr}`;
    }
    uni.navigateTo({
        url: data,
    });
}
// 重定向到某个页面 此操作是 当前页面出栈，再入栈目标页面 之前已打开的页面不会出栈，所以跳转回首页不能用它，否则跳转10遍回首页 就无法再打开页面了
export function redirectTo(data, query) {
    if (isValidObject(data)) {
        uni.redirectTo(data);
        return;
    }
    data = combinePath(data);
    if (isValidObject(query)) {
        const queryStr = encodeSearchParams(query);
        data = `${data}?${queryStr}`;
    }
    uni.redirectTo({
        url: data,
    });
}
// 重定向到某个页面 此操作是 当前页面出栈，再入栈目标页面 之前已打开的页面不会出栈，所以跳转回首页不能用它，否则跳转10遍回首页 就无法再打开页面了
export function redirectToCheckLogin(data, query) {
    if (data.url === PAGES.REGISTER.MAIN || data === PAGES.REGISTER.MAIN) {
        updateUserInfo().then((result) => {
            if (result.isReg) {
                uni.redirectTo({ url: PAGES.LOGIN });
            }
            else if (!result.isReg) {
                uni.redirectTo({ url: PAGES.REGISTER.MAIN });
            }
        });
        return;
    }
    if (isValidObject(data)) {
        uni.redirectTo(data);
        return;
    }
    data = combinePath(data);
    if (isValidObject(query)) {
        const queryStr = encodeSearchParams(query);
        data = `${data}?${queryStr}`;
    }
    uni.redirectTo({
        url: data,
    });
}
// 关闭所有页面 并跳转到某页 一般预约成功 支付成功用此跳转
export function reLaunch(data, query) {
    console.log('reLaunch:', data);
    // 跳转路由的特殊情况：
    // 如果传入的路由是想要进入REGISTER注册页面，则判断最终是去REGISTER注册页面 还是 LOGIN登录页面
    if (data.url === PAGES.REGISTER.MAIN || data === PAGES.REGISTER.MAIN) {
        updateUserInfo()
            .then((result) => {
            if (result.isReg) {
                uni.navigateTo({ url: PAGES.LOGIN });
            }
            else if (!result.isReg) {
                uni.navigateTo({ url: PAGES.REGISTER.MAIN });
            }
        })
            .catch(() => {
            uni.navigateTo({ url: PAGES.LOGIN });
        });
        return;
    }
    if (isValidObject(data)) {
        uni.reLaunch(data);
        return;
    }
    data = combinePath(data);
    if (isValidObject(query)) {
        const queryStr = encodeSearchParams(query);
        data = `${data}?${queryStr}`;
    }
    console.log('reLaunch2go:', data);
    uni.reLaunch({
        url: data,
    });
}
/*
 * 返回上一级页面 关闭当前页面，返回上一页面或多级页面
 * delta: 1 返回的页面数，如果 delta 大于现有页面数，则返回到首页。
 * */
export function navigateBack(data) {
    if (isValidObject(data)) {
        uni.navigateBack(data);
        return;
    }
    uni.navigateBack({ delta: 1 });
}
/**
 * 回到首页或者任何进来的地方
 */
export function goBackHomeOrAnywhere() {
    // 进入登录模块前存储的地址
    const redirectUrl = UFHStorage.getStorageData(STORAGE_KEY.REDIRECT_URL);
    UFHStorage.removeStorageData(STORAGE_KEY.REDIRECT_URL);
    if (redirectUrl) {
        const pages = getCurrentPages().reverse();
        let delta = 0;
        for (let i = 0; i < pages.length; i++) {
            if (pages[i].route.startsWith('pages/login') || pages[i].route.startsWith('pages/register')) {
                delta += 1;
            }
        }
        console.log('delta:', delta);
        navigateBack({
            delta: delta,
            success: function () {
                console.log('navigateBack:', 'success');
                navigateTo(redirectUrl);
            },
            fail: function () {
                // 当pages 为1 的时候，会执行失败
                console.log('navigateBack:', 'fail');
                redirectTo(redirectUrl);
            },
            complete: function () {
                console.log('navigateBack:', 'complete');
            },
        });
    }
    else {
        uni.switchTab({
            url: PAGES.TABS.HOME,
        });
    }
}
/*
 * 打开另一个小程序
 * */
export function navigateToMiniProgram(data) {
    if (isValidObject(data)) {
        uni.navigateToMiniProgram(data);
        return;
    }
    uni.navigateToMiniProgram({
        appId: data,
    });
}
/**
 * sda
 * @param phone
 * @returns {Promise<boolean>}
 */
export async function checkPhone(phone) {
    // 手机号校验
    const phoneReg = /^1\d{10}$/;
    if (isEmpty(phone)) {
        return Promise.reject(new Error('empty phone'));
    }
    if (!phoneReg.test(phone)) {
        return Promise.reject(new Error('error phone'));
    }
    else {
        return Promise.resolve(true);
    }
}
/**
 * check area phone
 * @param phone
 * @param areaCode
 * @returns {Promise<boolean>}
 */
export async function checkAreaPhone(phone, areaCode) {
    if (isEmpty(phone)) {
        return Promise.reject(new Error('empty phone'));
    }
    if (areaCode === '86') {
        return checkPhone(phone);
    }
    else {
        if (phone.length > 11) {
            return Promise.reject(new Error('error phone'));
        }
        else {
            return Promise.resolve(true);
        }
    }
}
// 非空校验 传递值 跟为空时的报错信息 message 非必填 不填时也可用来校验对象是否为空
export function isEmpty(val, message) {
    const reg = /\s/;
    if (typeof val === 'string' && reg.test(val)) {
        // errorModal("请不要输入空格");
        return true;
    }
    if (!val) {
        message && errorModal(message);
        return true;
    }
    else if (isObject(val)) {
        return val.length === 0;
    }
    else {
        return false;
    }
}
/*
  封装 小程序 模态框 api
  opt {
    title:title文案
    content:提示文案
    cancelText:取消按钮文案,
    confirmText:确认按钮文案,
    showCancel:是否显示取消按钮,
    success:点击确定按钮的回调函数,
    fail：点击取消按钮的回调函数
  }
*/
export function showModal(opt) {
    const lang = getLocalLocale();
    let cancelText = '取消';
    let confirmText = '确认';
    if (lang && lang === 'en') {
        cancelText = 'Cancel';
        confirmText = 'OK';
    }
    const showCancel = Boolean(opt.showCancel);
    const resultOpt = {
        content: opt.content,
        cancelText: opt.cancelText || cancelText,
        confirmText: opt.confirmText || confirmText,
        showCancel: showCancel,
        cancelColor: '#181818',
        confirmColor: '#007C95',
        success: (res) => {
            if (res.confirm) {
                if (opt.success) {
                    opt.success(res);
                }
            }
            if (res.cancel) {
                if (opt.fail) {
                    opt.fail(res);
                }
            }
        },
    };
    if (opt.title) {
        resultOpt.title = opt.title;
    }
    uni.showModal(resultOpt);
}
/*
  错误提示弹框 content必填，
  若有点击回调第二个传一个回调函数
*/
export function errorModal(content, success, fail, options) {
    const opt = {
        content: content,
        showCancel: false,
    };
    // 当传递 弹框成功的回调函数时
    if (success && typeof success === 'function') {
        opt.success = success;
    }
    // 当传递 取消回调函数时
    if (fail && typeof fail === 'function') {
        opt.fail = fail;
    }
    if (options?.title) {
        opt.title = options.title;
    }
    showModal(opt);
}
/*
 * 显示消息提示框
 * */
export function previewImage(data) {
    if (isValidObject(data)) {
        // 如果是config调用原方法
        uni.previewImage(data);
    }
    else {
        uni.previewImage({ urls: data });
    }
}
/*
 * 显示消息提示框
 * */
export function showToast(data) {
    if (isValidObject(data)) {
        // 如果是config调用原方法
        uni.showToast(data);
    }
    else {
        uni.showToast({ title: data });
    }
}
/**
 * 重置数据
 * @param {any} that 页面对象
 * @return {void}
 */
export function reset(that) {
    Object.assign(that.$data, that?.$options?.data());
}
/**
 *根据page的组件名获取页面路径
 * @param {string} name
 */
export function getPagePath(name) {
    return '/pages/' + name + '/main';
}
// 处理重复点击
export const handleMultiClick = function (fn, threshhold) {
    let isClick = false;
    return function () {
        // eslint-disable-next-line prefer-rest-params
        const args = arguments;
        // @ts-ignore
        const context = this;
        if (!isClick) {
            isClick = true;
            // @ts-ignore
            fn.apply(context, args);
            setTimeout(function () {
                isClick = false;
            }, threshhold);
        }
    };
};
/**
 * 序列化深拷贝 注：只能拷贝json可以表示的基本对象
 * @param obj
 * @returns {any}
 */
export function jsonDeepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}
/**
 * 解析身份证信息
 */
export function getCardInfo(card) {
    card = card?.trim();
    // 15号码兼容处理
    card = getIdCardNumber18(card);
    if (!card) {
        return {};
    }
    const year = card.substring(6, 10);
    const month = card.substring(10, 12);
    const day = card.substring(12, 14);
    const sex = parseInt(card.substring(17, 1)) % 2 === 0 ? 'F' : 'M';
    return { year, month, day, sex };
}
/**
 * 15位身份证转换为18位
 */
function getIdCardNumber18(idCardNumber) {
    if (!idCardNumber) {
        return null;
    }
    // 转换为字符串
    idCardNumber = '' + idCardNumber;
    if (idCardNumber.length === 18) {
        return idCardNumber;
    }
    else if (idCardNumber.length !== 15) {
        return null;
    }
    /* 首先将身份证号码扩展至17位: 将出生年扩展为19XX的形式 */
    const idCardNumber17 = idCardNumber.substring(0, 6) + '19' + idCardNumber.substring(6);
    /* 计算校验码 */
    let nSum = 0;
    for (let nCount = 0; nCount < 17; nCount++) {
        nSum += parseInt(idCardNumber17[nCount], 10) * (Math.pow(2, 17 - nCount) % 11);
    }
    nSum = nSum % 11;
    if (nSum <= 1) {
        nSum = 1 - nSum;
    }
    else {
        nSum = 12 - nSum;
    }
    /*
     * BUGFIX:18位身份证最后一位应该是X
     */
    if (nSum === 10) {
        return idCardNumber17 + 'X';
    }
    else {
        return idCardNumber17 + nSum;
    }
}
/*
 * 打电话
 * */
export function makePhoneCall(phoneNumber) {
    if (!phoneNumber)
        return;
    uni.makePhoneCall({
        phoneNumber,
    });
}
/*
 * 电话预约提示
 * */
export function processPhoneCall({ phoneCallMsg, cantApptOkTxt, cantApptCancelTxt, }) {
    showModal({
        content: phoneCallMsg,
        confirmText: cantApptOkTxt,
        cancelText: cantApptCancelTxt,
        showCancel: true,
        success: () => {
            const phoneNumber = DataDictHelper.queryDataDictWithKey(DataDictHelper.KEYS.PHONE);
            if (phoneNumber) {
                makePhoneCall(phoneNumber);
            }
        },
    });
}
/**
 * 验证身份证号码
 * @param card
 * @returns {boolean}
 */
export function isIdCard(card) {
    card = card?.trim();
    if (!card) {
        return false;
    }
    const match = /(^\d{17}([0-9]|X)$)/.test(card?.toUpperCase());
    if (match) {
        const { year, month, day } = getCardInfo(card);
        const birth = `${year}-${month}-${day}`;
        if (!dayjs(birth, 'YYYY-MM-DD', true).isValid()) {
            // 年月日不是正确的日期，返回错误
            return false;
        }
    }
    return match;
}
/**
 * 身份证号码校验位
 * @param card
 * @returns {boolean}
 */
function checkParity(card) {
    const arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
    const arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
    let cardTemp = 0;
    let remainder;
    for (let i = 0; i < 17; i++) {
        // @ts-ignore
        cardTemp += card.substr(i, 1) * arrInt[i];
    }
    // eslint-disable-next-line prefer-const
    remainder = arrCh[cardTemp % 11];
    // eslint-disable-next-line eqeqeq
    return remainder === card.substr(17, 1).toLocaleUpperCase();
}
/**
 * 证件号校验 （只包含数字和字母）
 * @param number
 * @returns {boolean}
 */
export function checkCredentialsNumber(number) {
    return /^[A-Za-z0-9]{1,20}$/.test(number);
}
/**
 * 是否开通在线客服，通过数据字典获取
 */
export function customerService() {
    // @ts-ignore
    return DataDictHelper.queryDataDictWithKey(DataDictHelper.KEYS.MINI_PROGRAM_ONLINE_SERVICE_IS_OPEN);
}
/**
 * 百度坐标系 (BD-09) 与 火星坐标系 (GCJ-02)的转换
 * @param bdLon
 * @param bdLat
 * @returns {number[]}
 */
export function bd09togcj02(bdLon, bdLat) {
    const xPi = (3.14159265358979324 * 3000.0) / 180.0;
    const x = bdLon - 0.0065;
    const y = bdLat - 0.006;
    const z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * xPi);
    const theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * xPi);
    const ggLng = z * Math.cos(theta);
    const ggLat = z * Math.sin(theta);
    return [ggLng, ggLat];
}
/*
 * 获取初始化数据
 * */
export async function getInitData() {
    // TODO: [answer] 数据拆分开放到 storage 里面, 防止单个大小超过 1M 限制
    // eslint-disable-next-line no-unreachable
    const rsp = await apiService.get('/hospital/dict/all/mapp', HttpHelper.parseGetConfig({}, true));
    if (rsp.code === 0) {
        UFHStorage.setStorageData(STORAGE_KEY.GLOBAL_DATA_DICT, rsp.data);
        initDICT(rsp.data);
    }
    return rsp;
}
export function random(Min, Max) {
    const Range = Max - Min;
    const Rand = Math.random();
    return Min + Math.round(Rand * Range);
}
export function validatePhone(phone) {
    if (!phone) {
        return false;
    }
    const phoneReg = /^1\d{10}$/;
    return phoneReg.test(phone);
}
export function validateEmail(email) {
    if (!email) {
        return false;
    }
    const mailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
    return mailReg.test(email);
}
export function trim(str) {
    if (!str) {
        return '';
    }
    return str.trim();
}
export function compareVersion(_v1, _v2) {
    const v1 = _v1.split('.');
    const v2 = _v2.split('.');
    const len = Math.max(v1.length, v2.length);
    while (v1.length < len) {
        v1.push('0');
    }
    while (v2.length < len) {
        v2.push('0');
    }
    for (let i = 0; i < len; i++) {
        const num1 = parseInt(v1[i]);
        const num2 = parseInt(v2[i]);
        if (num1 > num2) {
            return 1;
        }
        else if (num1 < num2) {
            return -1;
        }
    }
    return 0;
}
export async function requestSubscribeMessage(params = {}, complete) {
    const { subMsgList = [] } = params;
    if (!subMsgList || subMsgList.length <= 0) {
        return Promise.resolve();
    }
    // @ts-ignore
    const { system, version } = getSystemInfoSync();
    let supportMulti = true;
    if (system.toLowerCase().includes('ios')) {
        // ios
        if (compareVersion(version, '7.0.6') <= 0) {
            // 不支持多条
            supportMulti = false;
        }
    }
    else {
        // android
        if (compareVersion(version, '7.0.7') <= 0) {
            // 不支持多条
            supportMulti = false;
        }
    }
    // 需要申请的权限
    let tmplIds = [];
    if (supportMulti) {
        tmplIds = subMsgList.map((v) => v.templateId);
    }
    else if (subMsgList.length) {
        // 不支持多条，默认取第一条
        tmplIds = [subMsgList[0].templateId];
    }
    return new Promise((resolve) => {
        const isUse = uni.canIUse('requestSubscribeMessage');
        if (!isUse || !subMsgList.length) {
            !isUse && console.warn('当前微信版本不支持订阅消息授权');
            !subMsgList.length && console.warn('订阅消息模板ID列表为空');
            // 不能使用当前的api,获取订阅消息模板id列表为空
            complete && complete();
            resolve();
            return;
        }
        // 每次申请所有订阅消息授权
        uni.requestSubscribeMessage({
            tmplIds: tmplIds,
            success: (result) => {
                // 成功
                const { errMsg, ...subscriptionsSetting } = result || {};
                if (errMsg === 'requestSubscribeMessage:ok') {
                    // 点击了确定,获取是未授权状态
                    Object.keys(subscriptionsSetting).forEach((key) => {
                        // @ts-ignore
                        const status = subscriptionsSetting[key];
                        // 未授权的订阅消息
                        if (status === 'reject' && tmplIds.includes(key)) {
                            //
                        }
                        // 授权的订阅消息,并且在请求授权的列表中
                        if (status === 'accept' && tmplIds.includes(key)) {
                            try {
                                if ([APP_MSG_VISIT_ENUM.VISIT_MSG_CN, APP_MSG_VISIT_ENUM.VISIT_MSG_EN].includes(subMsgList.filter((v) => v.templateId === key)[0].msgType)
                                // eslint-disable-next-line no-empty
                                ) {
                                }
                            }
                            catch (e) {
                                //
                            }
                        }
                    });
                }
                console.log('订阅消息授权成功', result);
            },
            fail: (result) => {
                console.log('获取订阅消息授权失败', result);
                // 失败
                const { errCode } = result;
                // eslint-disable-next-line no-empty
                if (errCode === 20004) {
                }
            },
            complete: () => {
                resolve();
                complete && complete();
            },
        });
    });
}
/**
 * 回退到某个页面
 * @param route
 * @return >0 需要回退的页面数，<=0 不需要回退
 */
export function getOffsetOfPage(route) {
    const pages = getCurrentPages();
    const pIndex = pages.findIndex((v) => v.route === route);
    if (pIndex === -1) {
        return -1;
    }
    return pages.length - (pIndex + 1);
}
export function showLoading(data) {
    const title = i18n.t('i18n_vcsn23eb_1651741146218_info');
    if (isValidObject(data)) {
        uni.showLoading({
            title,
            mask: true,
            ...data,
        });
        return;
    }
    uni.showLoading({
        title: data || title,
        mask: true,
    });
}
export function hideLoading() {
    uni.hideLoading();
}
export function strReplaceWithAsterisk(phoneNumber) {
    if (!phoneNumber) {
        return '';
    }
    const hideStr = phoneNumber.substring(3, phoneNumber.length - 4);
    return phoneNumber.replace(hideStr, '****');
}
export function createSelectorQuery() {
    return uni.createSelectorQuery();
}
/*
 * 获取当前页面路径
 * */
export function getCurrentPath() {
    const pages = getCurrentPages();
    return pages[pages.length - 1].route;
}
/*
 * 使用应用内置地图查看位置
 * */
export function openLocation(config) {
    isValidObject(config) && uni.openLocation(config);
}
/*
 * 支付
 * */
export function requestPayment(option) {
    isValidObject(option) && uni.requestPayment(option);
}
/*
 * 支付
 * */
export function getSystemInfoSync(key) {
    // @ts-ignore
    return key ? uni.getSystemInfoSync()[key] : uni.getSystemInfoSync();
}
/*
 * 获取用户的当前设置
 * */
export function getSetting(config) {
    return uni.getSetting(config);
}
/**
 * 打开设置界面
 * @param config
 */
export function openSetting(config) {
    return uni.openSetting(config);
}
export function createCameraContext() {
    return uni.createCameraContext();
}
/*
 * 获取用户的当前设置
 * */
// export function getUserInfo(config: UniApp.GetUserInfoOptions): void {
//     isValidObject(config) && uni.getUserInfo(config);
// }
/*
 * 小程序的原生菜单中显示分享按钮
 * */
export function showShareMenu(config) {
    isValidObject(config) && uni.showShareMenu(config);
}
/*
 * 检查登录状态是否过期
 * */
export function checkSession(config) {
    isValidObject(config) && uni.checkSession(config);
}
/*
 * 获取网络状态
 * */
export function getNetworkType(config) {
    isValidObject(config) && uni.getNetworkType(config);
}
/*
 * 网络状态改变
 * */
export function onNetworkStatusChange(callback) {
    callback && uni.onNetworkStatusChange(callback);
}
/*
 * 获取定位
 * */
export function getLocation(config) {
    isValidObject(config) && uni.getLocation(config);
}
/*
 * 设置剪贴板
 * */
export function setClipboardData(config) {
    if (isValidObject(config)) {
        uni.setClipboardData(config);
        return;
    }
    uni.setClipboardData({
        data: config || '',
    });
}
export function getRect(selector, all) {
    return new Promise((resolve) => {
        createSelectorQuery()
            // @ts-ignore
            .in(this)[all ? 'selectAll' : 'select'](selector)
            .boundingClientRect((rect) => {
            if (all && Array.isArray(rect) && rect.length) {
                resolve(rect);
            }
            if (!all && rect) {
                resolve(rect);
            }
        })
            .exec();
    });
}
/*
 * 合并多个对象
 * Recursively merge properties of two objects
 */
export function mergeRecursive(source, target) {
    for (const p in target) {
        // eslint-disable-next-line no-prototype-builtins
        if (target.hasOwnProperty(p)) {
            try {
                if (_.isObject(target[p])) {
                    source[p] = mergeRecursive(source[p], target[p]);
                }
                else {
                    source[p] = target[p];
                }
            }
            catch (e) {
                source[p] = target[p];
            }
        }
    }
    return source;
}
/*
 * fix保留小数点
 * */
export function numberToFixed(number, n) {
    if (n > 20 || n < 0) {
        throw new RangeError('toFixed() digits argument must be between 0 and 20');
    }
    if (isNaN(number) || number >= Math.pow(10, 21)) {
        return number.toString();
    }
    if (typeof n === 'undefined' || n === 0) {
        return Math.round(number).toString();
    }
    let result = number.toString();
    const arr = result.split('.');
    // 整数的情况
    if (arr.length < 2) {
        result += '.';
        for (let i = 0; i < n; i += 1) {
            result += '0';
        }
        return result;
    }
    const integer = arr[0];
    const decimal = arr[1];
    if (decimal.length === n) {
        return result;
    }
    if (decimal.length < n) {
        for (let i = 0; i < n - decimal.length; i += 1) {
            result += '0';
        }
        return result;
    }
    result = integer + '.' + decimal.substr(0, n);
    const last = decimal.substr(n, 1);
    // 四舍五入，转换为整数再处理，避免浮点数精度的损失
    if (parseInt(last, 10) >= 5) {
        const x = Math.pow(10, n);
        const _result = (Math.round(parseFloat(result) * x) + 1) / x;
        result = _result.toFixed(n);
    }
    return result;
}
export async function scanCode(complete) {
    return new Promise((resolve, reject) => {
        uni.scanCode({
            success: (res) => {
                resolve(res);
            },
            fail: (e) => {
                reject(e);
            },
            complete: () => {
                complete && complete();
            },
        });
    });
}
/**
 * oss 地址拼接
 * @param path 路径
 * @returns 全路径
 */
export function combineCdnUrl(path) {
    if (!path.startsWith('/'))
        path = `/${path}`;
    return process.env.VUE_APP_CDN_URL + path;
}
export function encodeParams(params) {
    if (!params) {
        return '';
    }
    return encodeURIComponent(JSON.stringify(params));
}
export function decodeParams(params) {
    if (!params) {
        return {};
    }
    return JSON.parse(decodeURIComponent(params));
}
export function encodeParamsWithParamsKey(params) {
    return { params: encodeParams(params) };
}
export function decodeParamsWithParamsKey(query) {
    return decodeParams(query.params);
}
/**
 * 校验出生日期是否小于18岁
 * @param date
 */
export function checkAgeIsBelow18(date) {
    if (!date) {
        return false;
    }
    const offYear = dayjs(date, 'YYYY-MM-DD').add(18, 'year').startOf('day');
    const now = dayjs().startOf('day');
    return offYear.isAfter(now) || offYear.isSame(now);
}
/**
 * 关系字典
 */
export function relationDict() {
    return [
        { value: 2, label: i18n.t('i18n_uc3ae2n4_1651741146220_info') },
        { value: 3, label: i18n.t('i18n_tfwh43nz_1651741146220_info') },
        { value: 4, label: i18n.t('i18n_bvxk9crg_1651741146217_info') },
        { value: 5, label: i18n.t('i18n_6vzbn9ag_1651741146209_info') },
    ];
}
// catch
export const catchEmpty = (e) => {
    console.error(e);
};
/**
 * byte转KB、MB、GB
 * @param bytes
 * @returns {string}
 */
export function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (_.isNaN(Number(bytes))) {
        return '';
    }
    if (Number(bytes) === 0)
        return '0 Byte';
    const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
}
export function uuid() {
    const s = [];
    const hexDigits = '0123456789abcdef';
    for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4';
    // @ts-ignore
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-';
    const uuid = s.join('');
    return uuid;
}
/**
 * @description: 延迟执行
 * @param {number} duration
 * @return {*}
 */
export function sleep(duration) {
    return new Promise((resolve) => setTimeout((value) => resolve(value), duration));
}
/**
 * 对象转css字符串
 * @param {Record<string, any>} style
 * @returns {string}
 */
export const toCssStr = (style) => {
    return Object.keys(style).reduce((acc, key) => acc +
        key
            .split(/(?=[A-Z])/)
            .join('-')
            .toLowerCase() +
        ':' +
        style[key] +
        ';', '');
};
/**
 * 获取用户手机信息
 * @param code
 */
export const getUserMobileInfo = (code) => {
    return wx.cloud.callFunction({
        name: 'getMobilePhone',
        data: {
            code,
        },
    });
};
/**
 * 获取用户头像和昵称
 */
export const getUserProfile = () => {
    return wx.getUserProfile({
        desc: '用于完善用户资料',
    });
};
/**
 * 获取用户 code, 用来换取 openId, unionId
 */
export const getUserCode = () => {
    return new Promise((resolve, reject) => {
        // session_key 已经失效，需要重新执行登录流程
        wx.login({
            success: (res) => {
                console.log('wx.login:', res);
                resolve(res);
            },
            fail: (err) => {
                reject(err);
            },
        });
    });
};
/**
 * 更新用户 session key
 * @param code
 * @param loading
 */
export const updateSessionKeyWithCode = (code, loading = true) => {
    return new Promise((resolve, reject) => {
        apiService
            .post('/wechat/user/update-session-key', { code }, HttpHelper.parsePostConfig(true))
            .then((result) => {
            if (result.code === 0) {
                resolve(result);
            }
        })
            .catch((err) => {
            reject(err);
        });
    });
};
/**
 * 根据 code 换取用户 token
 */
export const getTokenWithCode = (code, loading = true) => {
    return new Promise((resolve, reject) => {
        apiService
            .post('/wechat/user/wx-login', { code }, HttpHelper.parsePostConfig(true))
            .then((result) => {
            if (result.code === 0) {
                if (result.data?.token) {
                    UFHStorage.setStorageData(STORAGE_KEY.TOKEN, result.data.token);
                    // try {
                    //   wx.setStorageSync(STORAGE_KEY.TOKEN, result.data.token);
                    // } catch (e) {}
                    // code 获取 token 时, 后端会同时更新 session key
                    UFHStorage.setStorageData(STORAGE_KEY.LAST_UPDATE_SESSION_KEY_TIME, Date.now());
                }
                resolve(result.data);
            }
        })
            .catch((err) => {
            reject(err);
        });
    });
};
/**
 * 登录状态获取用户信息
 */
export const getUserInfo = () => {
    return apiService.post('/wechat/user/info', {}, HttpHelper.parsePostConfig(false));
};
/**
 * 记录扫码进来的信息
 */
export const scanUploadinfo = (params = {}) => {
    return apiService.post('/hospital/marketing-channel/channel-item/create', params, HttpHelper.parsePostConfig(false));
};
/**
 * 生成太阳码
 */
export const createChannelImg = () => {
    return apiService.get('/hospital/external/marketing-channel/channel-item/gen/channel-img', {}, HttpHelper.parseGetConfig(false));
};
/**
 * 获取用户信息, 并更新本地 storage
 */
export const updateUserInfo = () => {
    return new Promise((resolve, reject) => {
        getUserInfo().then((result) => {
            if (result.code === 0) {
                UFHStorage.updateGlobalUserInfo(result.data);
                resolve(result.data);
            }
            else {
                reject(result.msg);
            }
        });
    });
};
/**
 * 获取用户定位
 */
// export const chooseUserLocation = () => {
//   return wx.chooseLocation({});
// };
export const getUserLocation = async () => {
    try {
        return await wx.getLocation({
            type: 'gcj02',
        });
    }
    catch (e) {
        return {};
    }
};
/**
 * 校验出生日期是否小于xx岁
 * @param date 日期
 * @param age 年龄
 */
export function checkAgeBelow(date, age) {
    if (!date) {
        return false;
    }
    return dayjs().startOf('day').diff(dayjs(date).startOf('day'), 'year', true) < age;
}
export function checkNeedAuth(p) {
    if (!p) {
        return false;
    }
    if (p.patientRelationType === UFH_RELATION_TYPE.SELF) {
        return false;
    }
    return !(p.patientRelationType === UFH_RELATION_TYPE.CHILDREN && checkAgeBelow(p.birthday, 18));
}
// 匹配cos key并返回
export function matchCosKey(url) {
    if (!url) {
        return null;
    }
    const match = url.match(/\.myqcloud\.com\/(.+)\?/)[1];
    return match ? match : url;
}
/**
 * 可选链操作方法，可直接用于 template
 * @param {*} target 对象源
 * @returns any
 */
export function useOptionalChain(target, key) {
    const keys = key.split('?.');
    return keys.reduce((a, b) => a?.[b], target);
}
/***
 *  语言兼容处理  zh = zh_CN en = en_US
 * @param ( lang ,type : 1.zh-zh_CN  2.zh_CN-zh)
 *
 */
export function languageConvert(lang, type) {
    const baseLang = {
        zh_CN: 'zh',
        en_US: 'en',
    };
    const standardLang = {
        zh: 'zh_CN',
        en: 'en_US',
    };
    const localLang = {
        zh_CN: 'zh',
        en_US: 'en',
        zh: 'zh',
        en: 'en',
    };
    if (type === 1) {
        return standardLang[lang];
    }
    if (type === 2) {
        return baseLang[lang];
    }
    // 默认 全部转化成本地的
    return localLang[lang];
}
/**
 * 对html里面的元素进行兼容处理
 * 1. 处理rich-text中大图显示不完整的问题
 * @param {用于rich-text的html代码} html
 */
export function parsedHtml(html) {
    if (html) {
        return html.replace(/\<img/gi, '<img style="max-width:100%;height:auto;display:block;"');
    }
    else {
        return html;
    }
}
