import zh from '../locale/zh.json';
import en from '../locale/en.json';

const config = {
  zh: { ...zh },
  en: { ...en },
  // 兼容
  zh_CN: { ...zh },
  en_US: { ...en },
};

export default config;
