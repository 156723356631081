<template><App :keepAliveInclude="keepAliveInclude"/></template><script>
export default {
  onLaunch: function () {
    console.log('App Launch');
  },
  onShow: function () {
    console.log('App Show');
  },
  onHide: function () {
    console.log('App Hide');
  },
};
</script>

<style>
/*每个页面公共css */
@import url('./style/iconfont_v2.css');
@import url('./style/iconfont_color.css');
@import url("./style/var.css");
@import url("./style/util.css");
</style>

<style lang="scss">
@import '~style/tailwind.scss';
@import "~style/comm.scss";

</style>