import Store from './store';
import { checkLogin } from './authorize';

/*
 * 显示信息
 * */
export const showMessage = (config) => {
  Store.commit('MessageStore/showMessage', config);
};

/*
 * 显示信息-带在线客服
 * */
export const showMessageWidthWidget = async (config) => {
  config.useWidget = true;
  let isLogin = false;
  try {
    isLogin = await checkLogin();
  } catch (e) {
    config.isLogin = false;
  }
  config.isLogin = isLogin;
  Store.commit('MessageStore/showMessage', config);
};

/*
 * 隐藏信息
 * */
export const hideMessage = () => {
  Store.commit('MessageStore/cancel');
};

/*
 * 取消
 * */
export const handleCancel = () => {
  Store.state.MessageStore.onCancel();
  setTimeout(() => {
    Store.state.MessageStore.calCelAutoClose && Store.commit('MessageStore/cancel');
  }, 0);
};

/*
 * 确定
 * */
export const handleOk = () => {
  Store.state.MessageStore.onConfirm();
  setTimeout(() => {
    Store.commit('MessageStore/confirm');
  }, 0);
};
