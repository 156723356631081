
import Vue from 'vue'

global['________'] = true;
delete global['________'];
global.__uniConfig = {"globalStyle":{"navigationBarTextStyle":"black","navigationBarTitleText":"uni-app","navigationBarBackgroundColor":"#F8F8F8","backgroundColor":"#F8F8F8"}};
global.__uniConfig.compilerVersion = '3.1.18';
global.__uniConfig.router = {"mode":"hash","base":"/"};
global.__uniConfig.publicPath = "/";
global.__uniConfig['async'] = {"loading":"AsyncLoading","error":"AsyncError","delay":200,"timeout":60000};
global.__uniConfig.debug = false;
global.__uniConfig.networkTimeout = {"request":60000,"connectSocket":60000,"uploadFile":60000,"downloadFile":60000};
global.__uniConfig.sdkConfigs = {};
global.__uniConfig.qqMapKey = "XVXBZ-NDMC4-JOGUS-XGIEE-QVHDZ-AMFV2";
global.__uniConfig.nvue = {"flex-direction":"column"}
global.__uniConfig.__webpack_chunk_load__ = __webpack_chunk_load__
Vue.component('pages-home-main', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/root/.jenkins/workspace/live-puh-h5/src/pages/home/main.vue')), 'pages-home-main'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-hospital-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/root/.jenkins/workspace/live-puh-h5/src/pages/hospital/list.vue')), 'pages-hospital-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-news-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/root/.jenkins/workspace/live-puh-h5/src/pages/news/list.vue')), 'pages-news-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-news-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/root/.jenkins/workspace/live-puh-h5/src/pages/news/detail.vue')), 'pages-news-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-doctor-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/root/.jenkins/workspace/live-puh-h5/src/pages/doctor/list.vue')), 'pages-doctor-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-doctor-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/root/.jenkins/workspace/live-puh-h5/src/pages/doctor/detail.vue')), 'pages-doctor-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-hospital-deptIntro-main', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/root/.jenkins/workspace/live-puh-h5/src/pages/hospital/deptIntro/main.vue')), 'pages-hospital-deptIntro-main'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-hospital-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/root/.jenkins/workspace/live-puh-h5/src/pages/hospital/detail.vue')), 'pages-hospital-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-hospital-deptIntro-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/root/.jenkins/workspace/live-puh-h5/src/pages/hospital/deptIntro/detail.vue')), 'pages-hospital-deptIntro-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-hospital-group-intro', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/root/.jenkins/workspace/live-puh-h5/src/pages/hospital/group-intro.vue')), 'pages-hospital-group-intro'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-privacy-userAgreement', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/root/.jenkins/workspace/live-puh-h5/src/pages/privacy/userAgreement.vue')), 'pages-privacy-userAgreement'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
global.__uniRoutes=[
{
path: '/',
alias:'/pages/home/main',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isEntry:true,

          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"金光医疗诊所","navigationStyle":"custom","backgroundColor":"#ededed"})
      },
      [
        createElement('pages-home-main', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:1,
  name:'pages-home-main',
  isNVue:false,maxWidth:0,
  pagePath:'pages/home/main',
isQuit:true,
isEntry:true,
  windowTop:0
}
},
{
path: '/pages/hospital/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTitleText":"院区列表"})
      },
      [
        createElement('pages-hospital-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-hospital-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/hospital/list',
  windowTop:0
}
},
{
path: '/pages/news/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTitleText":"院区动态"})
      },
      [
        createElement('pages-news-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-news-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/news/list',
  windowTop:0
}
},
{
path: '/pages/news/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTitleText":"院区动态"})
      },
      [
        createElement('pages-news-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-news-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/news/detail',
  windowTop:0
}
},
{
path: '/pages/doctor/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTitleText":"医生信息"})
      },
      [
        createElement('pages-doctor-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-doctor-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/doctor/list',
  windowTop:0
}
},
{
path: '/pages/doctor/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTitleText":"医生介绍"})
      },
      [
        createElement('pages-doctor-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-doctor-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/doctor/detail',
  windowTop:0
}
},
{
path: '/pages/hospital/deptIntro/main',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTitleText":"院区科室"})
      },
      [
        createElement('pages-hospital-deptIntro-main', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-hospital-deptIntro-main',
  isNVue:false,maxWidth:0,
  pagePath:'pages/hospital/deptIntro/main',
  windowTop:0
}
},
{
path: '/pages/hospital/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTitleText":"院区介绍"})
      },
      [
        createElement('pages-hospital-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-hospital-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/hospital/detail',
  windowTop:0
}
},
{
path: '/pages/hospital/deptIntro/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTitleText":"科室简介"})
      },
      [
        createElement('pages-hospital-deptIntro-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-hospital-deptIntro-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/hospital/deptIntro/detail',
  windowTop:0
}
},
{
path: '/pages/hospital/group-intro',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTitleText":"关于金光医疗"})
      },
      [
        createElement('pages-hospital-group-intro', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-hospital-group-intro',
  isNVue:false,maxWidth:0,
  pagePath:'pages/hospital/group-intro',
  windowTop:0
}
},
{
path: '/pages/privacy/userAgreement',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationStyle":"custom","navigationBarTitleText":"用户协议"})
      },
      [
        createElement('pages-privacy-userAgreement', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-privacy-userAgreement',
  isNVue:false,maxWidth:0,
  pagePath:'pages/privacy/userAgreement',
  windowTop:0
}
},
{
path: '/preview-image',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-preview-image', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'preview-image',
  pagePath:'/preview-image'
}
}
    ,
{
path: '/choose-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-choose-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'choose-location',
  pagePath:'/choose-location'
}
}
    ,
{
path: '/open-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-open-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'open-location',
  pagePath:'/open-location'
}
}
    ]
global.UniApp && new global.UniApp();
