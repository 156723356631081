import UFHStorage, { STORAGE_KEY } from '@/utils/UFHStorage';
import { getOpenIdLocal } from '@/utils/authorize';
import { API } from '@/utils/URLS';
import { errorModal, reLaunch } from '@/utils';
import { PAGES } from '@/utils/constants';
import _ from 'underscore';
import { isSuccess } from '@/utils/http';

export function applyRequestTokenBefore() {
  let isRequestToken = false; // 是否正在刷新token
  let requests = []; // 需要在刷新token后再调用的接口
  return (instance) => {
    instance.interceptors.request.use(
      async (config) => {
        const token = uni.getStorageSync(STORAGE_KEY.TOKEN);
        if (config.url.includes(API.AUTHORIZE) || token) {
          // 有token不拦截,授权接口不拦截
          return config;
        }
        if (isRequestToken) {
          // 等待token刷新，加入请求队列
          return new Promise((resolve, reject) => {
            requests.push((token) => {
              config.header['_t'] = token;
              resolve(config);
            });
          });
        }
        // 4:token超时、8:token参数异常，移除旧重新请求token，并重新请求原接口
        try {
          isRequestToken = true;
          // getOpenIdLocal这个接口会重新获取并设置token、openId等信息
          await getOpenIdLocal();
          const newToken = uni.getStorageSync(STORAGE_KEY.TOKEN);
          config.header['_t'] = newToken;
          requests.forEach((cb) => cb(newToken));
          requests = [];
          return config;
        } finally {
          isRequestToken = false;
        }
      },
      (config) => Promise.reject(config)
    );
  };
}

const resetToLogin = _.throttle(
  (response) => {
    // UFHStorage.clearStorage();
    reLaunch(PAGES.REGISTER.MAIN, null);
  },
  2000,
  { trailing: false }
);

/**
 * 刷新过期token
 */
export function applyRefreshExpiredToken() {
  let isRefreshToken = false; // 是否正在刷新token
  let requests = []; // 需要在刷新token后再调用的接口
  return (instance) => {
    instance.interceptors.response.use(
      async (response) => {
        if (response.config.url.includes(API.AUTHORIZE)) {
          // 授权接口，不走拦截
          return response;
        }

        // token失效，重新登录，清空登录状态
        if (response.data?.code === 20000 || response.data?.code === 20002) {
          requests = [];
          console.log(response.data);
          resetToLogin(response);
          return response;
        }

        if (![4, 8].includes(response.data?.code)) {
          if (!isSuccess(response.data?.code)) {
            // 异常
            if (response.config.custom?.exPrompt && response.data?.msg) {
              // 给异常提示弹窗
              errorModal(response.data?.msg);
            }
          }
          return response;
        }

        if (response.config.custom?._reRequest) {
          // 此处直接reject
          requests = [];
          return response;
        }
        //
        // 4:token超时、8:token参数异常，移除旧重新请求token，并重新请求原接口
        if (isRefreshToken) {
          // 等待token刷新，加入请求队列
          return new Promise((resolve, reject) => {
            requests.push((newToken) => {
              response.config.header['_t'] = newToken;
              response.config.baseURL = '';
              response.config.custom = { ...(response.config?.custom ?? {}), _reRequest: true }; // 程序自动重新发起的，在返回结果判断，如果还是失败，就直接reject
              if (newToken) {
                resolve(instance.request(response.config));
              } else {
                // 新的token为空，直接失败
                resolve(response);
              }
            });
          });
        }

        try {
          isRefreshToken = true;
          // token失效
          uni.removeStorageSync(STORAGE_KEY.TOKEN);
          await getOpenIdLocal();
          // getOpenIdLocal这个接口会重新获取并设置token、openId等信息
          const newToken = uni.getStorageSync(STORAGE_KEY.TOKEN);
          const config = response.config;
          config.header['_t'] = newToken;
          config.baseURL = '';
          config.custom = { ...(config.custom ?? {}), _reRequest: true }; // 程序自动重新发起的，在返回结果判断，如果还是失败，就直接reject
          requests.forEach((cb) => cb(newToken));
          requests = [];
          if (newToken) {
            return instance.request(config);
          } else {
            return response;
          }
        } finally {
          isRefreshToken = false;
        }
      },
      (response) => Promise.reject(response)
    );
  };
}
