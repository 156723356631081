import UFHStorage, { STORAGE_KEY } from './UFHStorage';

export async function getOpenIdLocal() {
  let openId = UFHStorage.getStorageData(STORAGE_KEY.OPEN_ID);
  let token = UFHStorage.getStorageData(STORAGE_KEY.TOKEN);
  if (!openId || !token) {
    try {
      // openId = await getOpenIdRemote()
    } catch (e) {}
  }
  return Promise.resolve(openId);
}

/* 通过token 校验登录状态 */
export async function checkLogin() {
  // token 一定会有, 只有 isLogin 表示用户是否登录
  let userInfo = UFHStorage.getStorageData(STORAGE_KEY.GLOBAL_USER_INFO);
  if (!userInfo.isLogin) {
    return Promise.reject(new Error('did not login'));
  }
  return Promise.resolve(true);
}
