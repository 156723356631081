import 'uni-pages';import 'uni-h5';import Vue from 'vue';
import App from './App';
import spIconNew from '@/components/spIconNew';
import VueCompositionAPI from '@vue/composition-api';
import mixin from './mixins/mixin';
import i18n from './i18n';

Vue.config.productionTip = false;

// 忽略打开微信小程序的标签
Vue.config.ignoredElements.push['wx-open-launch-weapp']

App.mpType = 'app';

if (['uat', 'fat', 'staging', 'dev'].includes(process.env.VUE_APP_ENV)) {
  // 测试环境默认打开调试
  // uni.setEnableDebug({
  //   enableDebug: true,
  // });
}
Vue.use(VueCompositionAPI);

function isPromise(obj) {
  return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function';
}

uni.addInterceptor({
  returnValue(res) {
    if (!isPromise(res)) {
      return res;
    }
    return new Promise((resolve, reject) => {
      res.then((res) => {
        if (res[0]) {
          reject(res[0]);
        } else {
          resolve(res[1]);
        }
      });
    });
  },
});

const app = new Vue({
  i18n,
  ...App,
});
app.$mount();

Vue.mixin(mixin);
Vue.component('SpIcon', spIconNew);