export const WEEK_DAY = ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thur.', 'Fri.', 'Sat.'];
export const WEEK_DAY_CN = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
export const EVENT_KEYS = {
    // 添加就诊人
    ADD_PATIENT: 'addPatient',
    // 刷新我的预约界面
    UPDATE_MY_RESERVATION: 'updateMyReservation',
    // 刷新我的预约界面
    UPDATE_MY_RESERVATION_DETAIL: 'updateMyReservationDetail',
    // 重新加载ocr识别界面数据
    RELOAD_PATIENT_NEW_DATA: 'reloadPatientNewData',
    // 就诊记录列表刷新
    VISIT_RECORD_REFRESH: 'visitRecordRefresh',
    // 选择就诊人回调
    PATIENT_SELECTED: 'patientSelected',
    // 我的订单页面更新
    ORDERS_REFRESH: 'ordersRefresh',
    // 语言变更
    LANGUAGE_CHANGED: 'languageChanged',
    ON_COUPON_SELECTED: 'onCouponSelected',
    // 待结账页面更新
    BILLS_STATUS_CHANGE: 'billStatusChange',
    // 添加就诊人并自动选中
    ADD_PATIENT_AND_AUTO_SELECT: 'addPatientAndAutoSelect',
    // 申报人填写信息
    ON_DECLARATION_DATA_CHANGE: 'onDeclarationDataChange',
    // 刷新核酸检测预约详情
    REFRESH_HSJC_DETAIL: 'onRefreshHsjcDetail',
    // 刷新体检订单列表
    UPDATE_ORDER_LIST: 'updateOrderList',
    CODE_VERIFI_CATION: 'codeverification',
    QUERY_PATIENT_SUCCESS: 'queryPatientSuccess',
    UPDATE_PATIENT_HEAD_URL: 'updatePatientHeadUrl',
    LOGIN_SUCCESS: 'loginSuccess',
    UPDATE_WECHAT_USER_INFO: 'update_wechat_user_info',
    REFRESH_BMI_LIST: 'refreshBmiList',
    FORGET_PWD_VERIFY_SUCCESS: 'forget_pwd_verify_success',
    REFRESH_REGISTER_LIST: 'refresh_register_list',
};
export var APP_MSG_VISIT_ENUM;
(function (APP_MSG_VISIT_ENUM) {
    // 中文
    APP_MSG_VISIT_ENUM[APP_MSG_VISIT_ENUM["VISIT_MSG_CN"] = 1] = "VISIT_MSG_CN";
    // 英文
    APP_MSG_VISIT_ENUM[APP_MSG_VISIT_ENUM["VISIT_MSG_EN"] = 2] = "VISIT_MSG_EN";
})(APP_MSG_VISIT_ENUM || (APP_MSG_VISIT_ENUM = {}));
export var EXTERNAL_MP_SOURCE;
(function (EXTERNAL_MP_SOURCE) {
    EXTERNAL_MP_SOURCE["I_HOSPITAL"] = "1";
    EXTERNAL_MP_SOURCE["I_SIGN_LIST"] = "2";
    EXTERNAL_MP_SOURCE["I_BIND_PATIENT_FINISH"] = "3";
})(EXTERNAL_MP_SOURCE || (EXTERNAL_MP_SOURCE = {}));
/*
 * 就诊人关系
 **/
export var PATIENT_RELATION;
(function (PATIENT_RELATION) {
    PATIENT_RELATION["UN_FILLED"] = "0";
    PATIENT_RELATION["SELF"] = "1";
    PATIENT_RELATION["CHILDREN"] = "2";
    PATIENT_RELATION["SPOUSE"] = "3";
    PATIENT_RELATION["PARENT"] = "5";
    PATIENT_RELATION["OTHER"] = "4";
    PATIENT_RELATION["RELATIVES"] = "6";
    PATIENT_RELATION["FRIEND"] = "7";
})(PATIENT_RELATION || (PATIENT_RELATION = {}));
/**
 * 就诊人类型
 */
export var PATIENT_TYPE;
(function (PATIENT_TYPE) {
    PATIENT_TYPE["UNKNOWN"] = "";
    PATIENT_TYPE["QUICK"] = "Q";
    PATIENT_TYPE["FULL"] = "F";
    PATIENT_TYPE["IMIGRATION"] = "I";
    PATIENT_TYPE["MEMBER"] = "M";
    PATIENT_TYPE["STAFF"] = "S";
})(PATIENT_TYPE || (PATIENT_TYPE = {}));
/*
 * 就诊人授权状态
 **/
export var PATIENT_AUTH_STATUS;
(function (PATIENT_AUTH_STATUS) {
    PATIENT_AUTH_STATUS[PATIENT_AUTH_STATUS["NO_NEED_AUTH"] = -1] = "NO_NEED_AUTH";
    PATIENT_AUTH_STATUS[PATIENT_AUTH_STATUS["UN_AUTH"] = 1] = "UN_AUTH";
    PATIENT_AUTH_STATUS[PATIENT_AUTH_STATUS["AUTHING"] = 2] = "AUTHING";
    PATIENT_AUTH_STATUS[PATIENT_AUTH_STATUS["AUTHED"] = 3] = "AUTHED";
    PATIENT_AUTH_STATUS[PATIENT_AUTH_STATUS["REJECTED"] = 4] = "REJECTED";
    PATIENT_AUTH_STATUS[PATIENT_AUTH_STATUS["TIME_OUT"] = 5] = "TIME_OUT";
    PATIENT_AUTH_STATUS[PATIENT_AUTH_STATUS["INVALID"] = 6] = "INVALID";
})(PATIENT_AUTH_STATUS || (PATIENT_AUTH_STATUS = {}));
export var PATIENT_AUTH_CODE;
(function (PATIENT_AUTH_CODE) {
    /**
     * 预约
     * @type {PATIENT_AUTH_CODE.APPT}
     */
    PATIENT_AUTH_CODE["APPT"] = "APPT";
    /**
     * 账单
     * @type {PATIENT_AUTH_CODE.BILL}
     */
    PATIENT_AUTH_CODE["BILL"] = "BILL";
    /**
     * 报告
     * @type {PATIENT_AUTH_CODE.REPORT}
     */
    PATIENT_AUTH_CODE["REPORT"] = "REPORT";
    /**
     * 满意度评价
     * @type {PATIENT_AUTH_CODE.EPISODE}
     */
    PATIENT_AUTH_CODE["EPISODE"] = "EPISODE";
    /**
     * 病史信息
     * @type {PATIENT_AUTH_CODE.MEDICAL_REPORT}
     */
    PATIENT_AUTH_CODE["MEDICAL_REPORT"] = "MEDICAL_REPORT";
})(PATIENT_AUTH_CODE || (PATIENT_AUTH_CODE = {}));
export const PAGES = {
    GLOBAL: {
        WEBVIEW: '/pages/global/webview/index',
    },
    MINE_SUB: {
        PATIENT_V3: {
            // 验证家人手机号
            VERIFICATION_PATIENT_MOBILE: '/pages/mineSub/patientV3/choiceAddPatient/verificationPatientMobile/main',
            // 补充关系
            BIND_PATIENT_WITH_RELATION: '/pages/mineSub/patientV3/bindPatientWithRelation/main',
        },
    },
    REGISTER: {
        MAIN: '/pages/register/main',
    },
    LOGIN: '/pages/login/main',
    TABS: {
        HOME: '/pages/home/main',
        MINE: '/pages/mine/main',
    },
    LOADING: '/pages/loading/main',
    HOSPITAL_DETAIL: '/pages/hospital/detail',
    DEPARTMENT_DETAIL_INFO: '/pages/hospital/deptIntro/detail',
    DEPARTMENT_DETAIL: '/pages/hospital/deptIntro/main',
    APPT: {
        DEPARTMENT: '/pages/appt/departments/index',
        DETAIL: '/pages/appt/apptDetail/index',
        MODIFY: '/pages/appt/apptModify/index',
        CONFIRM: '/pages/appt/apptConfirm/index',
        DOCTOR: '/pages/appt/doctors/index',
        APPT_DOCTOR: '/pages/appt/apptDoctor/index',
        APPT_APPTLIST: '/pages/appt/apptList/index', // 我的预约
    },
    PATIENT: {
        LIST: '/pages/patient/list/index',
        DETAIL: '/pages/patient/info/index',
        CHOICE: '/pages/patient/choice/index',
        CREATE: '/pages/patient/create/index',
        CREATE_QUICK: '/pages/patient/create/quick',
        CREATE_SUCCESS: '/pages/patient/create/success/index',
        QUERY_MRN: '/pages/patient/bind/queryMrn/index',
        UPDATE_CERT: '/pages/patient/info/updateCertificate/index',
        CAMERA: '/pages/patient/camera/index',
        INFO_VERIFICATION: '/pages/patient/infoVerify/index', // 信息校验(人脸核身, 手机实名)
    },
    FAMILY: {
        INSURANCE: '/pages/family/insurance',
    },
    BILLING: {
        LIST: '/pages/billing/billing-list',
        DETAIL: '/pages/billing/billing-detail',
        ADD_INVOICE: '/pages/billing/add-invoice',
        INVOICE_RESULT: '/pages/billing/invoice-result',
        INVOICE_PREVIEW: '/pages/billing/invoice-preview', // 发票预览
    },
    REPORT: {
        LIST: '/pages/report/report-list',
        INSPECTION: '/pages/report/inspection-report',
        CHECK: '/pages/report/check-report',
        PREVIEW: '/pages/report/report-preview',
    },
    /** 流调 */
    EPIDEMIC: {
        LIST: '/pages/epidemic/epidemic-list',
        SURVEY: '/pages/epidemic/epidemic-survey',
    },
    /**
     * 核酸检测
     */
    PCR: {
        MAIN: '/pages/packages/rtpcr/schedule/index',
    },
    EVALUATION: {
        SUBMIT: '/pages/evaluation/evaluationSubmit',
        DETAIL: '/pages/evaluation/evaluationDetail',
        LIST: '/pages/evaluation/evaluationList',
    },
    VACCINE: {
        RECORDS: '/pages/packages/vaccine/register/list/index',
        SCHEDULE: '/pages/packages/vaccine/schedule/index',
        INFLUENZA: '/pages/packages/vaccine/list/index',
        APPT_DETAIL: '/pages/packages/rtpcr/appt/detail/index',
    },
    BMI: {
        LIST: '/pages/bmi/bmi-list',
        CHART: '/pages/bmi/bmi-chart',
        ADD: '/pages/bmi/add-bmi',
    },
    /**
     * 优惠券
     */
    COUPON: {
        LIST: '/pages/coupon/couponList',
        DETAIL: '/pages/coupon/couponDetail',
        CODE: '/pages/coupon/couponCode',
    },
    PREGNANCY: {
        TRIP: '/pages/mine/pregnancy-trip',
    },
    /** 微店订单 */
    MICROSHOPORDER: {
        MAIN: '/pages/microshoporder/main',
        PRECONFIRM: '/pages/microshoporder/preConfirm',
        DOCTORS: '/pages/microshoporder/doctors',
        APPTCONFIRM: '/pages/micoshoporder/apptConfirm',
    },
    PASSWORD: {
        MODIFY: '/pages/password/modifyPwd',
    },
    /** 会员权益 */
    VIP_RIGHT: {
        MAIN: '/pages/vipright/main',
        DETAIL: '/pages/vipright/detail',
        CODE_INPUT: '/pages/vipright/code-input',
        CODE_ACTIVE: '/pages/vipright/code-active',
    },
    CHECKIN: {
        CHECKIN: '/pages/checkin/checkin',
    },
    CHECKUP: {
        DETAIL: '/pages/checkup/appointment/detail',
    },
};
/**
 * 表单项展示类型
 */
export const FORM_ITEM_TYPE = {
    TEXT: '1',
    PICKER_DATE: '2',
    INPUT: '3',
    PICKER_SELECT: '4',
    SWITCH: '5',
    TEXTAREA: '6',
};
/**
 * 证件类型
 * @type {{MAINLAND: string, BIRTH: string, ID_CARD: string, PASSPORT: string, TAIWANPT: string, REGISTERED_RESIDENCE: string}}
 */
export var CERTIFICATE_TYPE;
(function (CERTIFICATE_TYPE) {
    /** 身份证*/
    CERTIFICATE_TYPE["ID_CARD"] = "CHID";
    /** 护照*/
    CERTIFICATE_TYPE["PASSPORT"] = "PPN";
    /** 台胞证*/
    CERTIFICATE_TYPE["TAIWANPT"] = "006";
    /** 港澳通行证*/
    CERTIFICATE_TYPE["MAINLAND"] = "004";
    /** 出生证*/
    CERTIFICATE_TYPE["BIRTH"] = "BCID";
    /** 军官证*/
    CERTIFICATE_TYPE["SRVCE"] = "SRVCE";
    /** 外交官证*/
    CERTIFICATE_TYPE["DCID"] = "DCID";
    /** 其它*/
    CERTIFICATE_TYPE["OTHID"] = "OTHID";
    /**空 */
    CERTIFICATE_TYPE["NONE"] = "NONE";
})(CERTIFICATE_TYPE || (CERTIFICATE_TYPE = {}));
/**
 * 性别对应的枚举
 */
export var GENDER;
(function (GENDER) {
    GENDER["M"] = "M";
    GENDER["F"] = "F";
})(GENDER || (GENDER = {}));
/**
 * 国家映射
 */
export var COUNTRYCODE;
(function (COUNTRYCODE) {
    COUNTRYCODE["CHINA"] = "CN86";
    COUNTRYCODE["TAIWAN"] = "TW886";
    COUNTRYCODE["HK"] = "HK852";
    COUNTRYCODE["MACAO"] = "MO853";
})(COUNTRYCODE || (COUNTRYCODE = {}));
/**
 * 复姓
 */
export const CompoundSurnameArr = [
    '万俟',
    '司马',
    '上官',
    '欧阳',
    '夏侯',
    '诸葛',
    '闻人',
    '东方',
    '赫连',
    '皇甫',
    '羊舌',
    '尉迟',
    '公羊',
    '澹台',
    '公冶',
    '宗正',
    '濮阳',
    '淳于',
    '单于',
    '太叔',
    '申屠',
    '公孙',
    '仲孙',
    '轩辕',
    '令狐',
    '钟离',
    '宇文',
    '长孙',
    '慕容',
    '鲜于',
    '闾丘',
    '司徒',
    '司空',
    '兀官',
    '司寇',
    '南门',
    '呼延',
    '子车',
    '颛孙',
    '端木',
    '巫马',
    '公西',
    '漆雕',
    '车正',
    '壤驷',
    '公良',
    '拓跋',
    '夹谷',
    '宰父',
    '谷梁',
    '段干',
    '百里',
    '东郭',
    '微生',
    '梁丘',
    '左丘',
    '东门',
    '西门',
    '南宫',
    '第五',
    '公仪',
    '公乘',
    '太史',
    '仲长',
    '叔孙',
    '屈突',
    '尔朱',
    '东乡',
    '相里',
    '胡母',
    '司城',
    '张廖',
    '雍门',
    '毋丘',
    '贺兰',
    '綦毋',
    '屋庐',
    '独孤',
    '南郭',
    '北宫',
    '王孙',
];
