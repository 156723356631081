export default class CurlHelper {
    request;
    constructor(config) {
        this.request = config;
    }
    getHeaders() {
        let headers = this.request.header ?? {};
        let curlHeaders = '';
        // get the headers concerning the appropriate method (defined in the global axios instance)
        if (headers?.hasOwnProperty('common')) {
            headers = this.request.header?.[this.request.method];
        }
        // add any custom headers (defined upon calling methods like .get(), .post(), etc.)
        for (let property in this.request.header) {
            if (!['common', 'delete', 'get', 'head', 'patch', 'post', 'put'].includes(property)) {
                headers[property] = this.request.header[property];
            }
        }
        for (let property in headers) {
            if ({}.hasOwnProperty.call(headers, property)) {
                let header = `${property}:${headers[property]}`;
                curlHeaders = `${curlHeaders} -H "${header}"`;
            }
        }
        return curlHeaders.trim();
    }
    getMethod() {
        return `-X ${this.request.method.toUpperCase()}`;
    }
    getBody() {
        if (typeof this.request.data !== 'undefined' &&
            // @ts-ignore
            this.request.data !== '' &&
            this.request.data !== null &&
            // @ts-ignore
            this.request.method.toUpperCase() !== 'GET') {
            let data = typeof this.request.data === 'object' || Object.prototype.toString.call(this.request.data) === '[object Array]'
                ? JSON.stringify(this.request.data)
                : this.request.data;
            return `--data '${data}'`.trim();
        }
        else {
            return '';
        }
    }
    getUrl() {
        if (this.request.baseURL) {
            let baseUrl = this.request.baseURL;
            let url = this.request.url;
            let finalUrl = baseUrl + '/' + url;
            return finalUrl
                .replace(/\/{2,}/g, '/')
                .replace('http:/', 'http://')
                .replace('https:/', 'https://');
        }
        return this.request.url;
    }
    getQueryString() {
        let params = '';
        let i = 0;
        for (let param in this.request.params) {
            if ({}.hasOwnProperty.call(this.request.params, param)) {
                params += i !== 0 ? `&${param}=${this.request.params[param]}` : `?${param}=${this.request.params[param]}`;
                i++;
            }
        }
        return params;
    }
    getBuiltURL() {
        let url = this.getUrl();
        if (this.getQueryString() !== '') {
            url += this.getQueryString();
        }
        return url.trim();
    }
    generateCommand() {
        return `curl ${this.getMethod()} "${this.getBuiltURL()}" ${this.getHeaders()} ${this.getBody()}`.trim().replace(/\s{2,}/g, ' ');
    }
}
