/* eslint-disable camelcase */
import { errorModal, showLoading, hideLoading, getSystemInfoSync } from './index';
import { getLocalLocale } from './i18n';
import UFHStorage, { STORAGE_KEY } from './UFHStorage';
import i18n from '@/i18n';
import Request from 'luch-request';
import _ from 'underscore';
import wxHttp from '@/utils/wxHttp';
import { applyRefreshExpiredToken, applyRequestTokenBefore } from '@/http/interceptors/requestRefreshToken';
import CurlHelper from '@/http/CurlHelper';

let loadingCount = 0; // loading的弹出次数

export const languageMaps = {
  zh: 'zh_CN', // 中文
  en: 'en_US', // 英文
  // 兼容
  zh_CN: 'zh_CN', // 中文
  en_US: 'en_US', // 英文
};

function _hideLoading(config) {
  if (!config?.custom?.hideLoading) {
    loadingCount -= 1;
    if (loadingCount <= 0) {
      loadingCount = 0;
      hideLoading();
    }
  }
}

function printLogs(response, error) {
  if (['uat', 'fat', 'staging', 'dev'].includes(process.env.VUE_APP_ENV) && getSystemInfoSync('platform') !== 'devtools') {
    if (error) {
      console.error(response?.config?.fullPath);
      console.info(response);
      console.log(new CurlHelper(response.config).generateCommand(), 'color: red;');
    } else {
      console.log(`%c ${response?.config?.fullPath}`, 'color: green;');
      console.info(response);
      if (response.data?.code !== 0) {
        console.log(new CurlHelper(response.config).generateCommand(), 'color: red;');
      }
    }
  }
}

const showErrorModal = _.throttle(
  (msg, error) => {
    console.error(error);
    errorModal(msg || i18n.t('i18n_rtgqjw6n_1651741146220_info'));
  },
  3000,
  { trailing: false }
);

const processDateOffset = _.throttle(
  (resp) => {
    wxHttp.processDateOffset(resp.data);
  },
  3000,
  { trailing: false }
);

/**
 * 公共header
 * @returns {{_cv: *, _t: any, terminal: string, lang: *}}
 */
export function commonHeader() {
  const token = UFHStorage.getStorageData(STORAGE_KEY.TOKEN);
  // const lang = languageMaps[getLocalLocale()];
  const lang = 'zh_CN'

  return {
    terminal: 'wmp', // app-rest使用，区分平台类型
    _cv: process.env.VUE_APP_VERSION,
    lang,
    _t: token,
  };
}

const _applyRequestTokenBefore = applyRequestTokenBefore();
const _applyRefreshExpiredToken = applyRefreshExpiredToken();

const devMaps = {
  '/wechat': {
    host: 'http://47.103.66.220:9001'
  },

  '/patient': {
    host: 'http://47.103.66.220:9002'
  },

  '/hospital': {
    host: 'http://47.103.66.220:9003'
  },

  '/reserve': {
    host: 'http://47.103.66.220:9005'
  },

  '/episode': {
    host: 'http://47.103.66.220:9006'
  }
}

function createRequest(options) {
  const instance = new Request({
    validateStatus: (statusCode) => statusCode === 200,
    ...options,
    baseURL: options?.baseURL,
    sslVerify: false,
  });

  /**
   * 处理发起请求前token问题
   */
  _applyRequestTokenBefore(instance);

  instance.interceptors.request.use(
    async (config) => {
      return config;
    },
    (config) => config
  );

  /**
   * 参数和headers处理
   */
  instance.interceptors.request.use(
    async (config) => {
      // 处理header
      config.header = { ...commonHeader(), ...config.header };
      const method = config.method.toUpperCase();
      if (method === 'GET') {
        config.params = wxHttp.dealElement(config.params);
      }
      if (method === 'POST') {
        config.data = wxHttp.dealElement(config.data);
      }
      if (['DOWNLOAD', 'UPLOAD'].includes(method)) {
        config.formData = wxHttp.dealElement(config.formData);
      }
      if (!config?.custom?.hideLoading) {
        loadingCount += 1;
        showLoading({
          title: config?.custom?.options?.loadingMsg ?? i18n.t('i18n_vcsn23eb_1651741146218_info'),
          mask: true,
        });
      }
      return config;
    },
    (config) => {
      _hideLoading(config);
      return Promise.reject(config);
    }
  );

  /**
   * 处理loading问题和网络/服务器错误提示
   */
  instance.interceptors.response.use(
    async (response) => {
      _hideLoading(response.config);
      // 处理时间差问题
      processDateOffset(response);
      return response;
    },
    (response) => {
      _hideLoading(response.config);
      // 是否需要错误提示
      if (response.config.custom?.errPrompt !== false) {
        HttpHelper.handlerError(response);
      }
      return Promise.reject(response);
    }
  );

  /**
   * 处理token过期，重新刷新token问题
   */
  _applyRefreshExpiredToken(instance);

  /**
   * 处理返回结果
   */
  instance.interceptors.response.use(
    async (response) => {
      printLogs(response, false);
      // 处理错误弹窗
      if (response?.config.custom?.errPrompt === true) {
        if (response.data?.code !== 0 && response.data?.msg) {
          errorModal(response.data?.msg);
        }
      }
      return response?.data;
    },
    (response) => {
      printLogs(response, true);
      return { code: -10000, error: response };
    }
  );

  return instance;
}

/**
 * 基础的请求实例
 * @type {HttpRequest}
 */
export const apiService = createRequest({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

class HttpHelper {
  static parseGetConfig(params = {}, hideLoading = false, options = {}) {
    let config = {
      params,
      custom: { hideLoading, options },
      getTask: (task, _) => {
        if (options?.taskManager) {
          options?.taskManager.push(task);
        }
      },
    };
    if (options.baseURL) {
      config.baseURL = options.baseURL;
    }
    return config;
  }

  static parsePostConfig(hideLoading = false, options = {}) {
    let config = {
      custom: { hideLoading, options },
      getTask: (task, _) => {
        if (options?.taskManager) {
          options?.taskManager.push(task);
        }
      },
    };
    if (options.baseURL) {
      config['baseURL'] = options.baseURL;
    }
    return config;
  }

  /**
   * GET请求
   * @param {string} url 请求的url
   * @param {object} params 参数
   * @param {boolean} hideLoading 隐藏loading框
   * @param {object} options 配置
   * @returns {Promise<any>} 返回的RESULT
   */
  static async get(url, params = {}, hideLoading = false, options = {}) {
    return apiService.get(url, HttpHelper.parseGetConfig(params, hideLoading, options));
  }

  /**
   * POST请求
   * @param {string} url 请求的url
   * @param {object} body 参数
   * @param {boolean} hideLoading 隐藏loading框
   * @param {object} options 配置
   * @returns {Promise<any>} 返回的RESULT
   */
  static async post(url, body = {}, hideLoading = false, options = {}) {
    return apiService.post(url, body, HttpHelper.parsePostConfig(hideLoading, options));
  }

  /**
   * 处理ERROR、code处理
   * @param {object} error 错误信息
   * @return {void}
   */
  static handlerError(error) {
    const { data } = error;
    console.log('服务器错误 ---->', error);
    let msg = _.isObject(data) ? data?.msg : null;
    if (msg === '业务异常') {
      msg = data.em || data.msg;
    }
    showErrorModal(msg, error);
  }
}

export function isSuccess(code) {
  return code === 0;
}

export default HttpHelper;
